import * as React from "react";
import axios from "../../node_modules/axios";
import Store from "./store/store";
import { withRouter, RouteComponentProps } from "react-router";
import { observer } from "mobx-react";
import cookie from 'react-cookies';
import * as jwt from "jsonwebtoken";
import LoadingLogo from "./loadingLogo/LoadingLogo";
import { IUser } from "./parametersUsers/interfaces/IUser";
import Error from "./errorComponent/Error";
const stylesheet = require("../styles/styles.module.scss");

interface LoginProps {
  keyNameSessionLink: string;
}

interface LoginState {
  loadingAuth: boolean;
  error: boolean;
  errorType: string;
  errorMessage: string;
}

@observer
class Login extends React.Component<LoginProps & RouteComponentProps, LoginState> {
  constructor(props: LoginProps & RouteComponentProps) {
    super(props);
    this.state = {
      loadingAuth: true,
      error: false,
      errorType: "error",
      errorMessage: "Une erreur est survenue lors de la connexion à l'outil"
    }

  }

  componentDidMount() {
    const errorParams: any = this.props.match.params;
    if (errorParams != undefined && errorParams != null && errorParams.error != undefined && errorParams.error != null) {
      const errorType = errorParams.error;
      this.setErrorType(errorType);
    }
    else {
      // Si on est pas authentifié on récupére le mode de connexion dans la base et on fait la bonne authentification
      if (Store.userConnected.id == undefined || Store.userConnected.id == null) {
        axios.get(Store.wsPath + '/1/paramYes/getParamYes').then(async responseParam => {
          Store.authMode = responseParam.data.authMode;
          Store.paramYes = responseParam.data;
          if (responseParam.data.authMode == "Local") {
            // Dans le cas d'une auth locale
          }
          else if (responseParam.data.authMode == "AAD") {
            this.setState({ loadingAuth: true }, () => {
              this.connexionAAD().then(responseAAD => {
                // On mets l'utilisateur dans le Store
                Store.userConnected = responseAAD;

                // Load session start link
                const startLink: string = sessionStorage.getItem(this.props.keyNameSessionLink);

                if (startLink != undefined && startLink != null) {
                  sessionStorage.removeItem(this.props.keyNameSessionLink);
                  this.setState({ loadingAuth: false }, () => {
                    window.history.replaceState({}, "Lyka", startLink);
                    this.props.history.push(startLink);
                  });
                }
                else {
                  this.setState({ loadingAuth: false }, () => {
                    window.history.replaceState({}, "Lyka", "/");
                    this.props.history.push("/");
                  });
                }
              }).catch(error => {
                let errorType = "error";
                if (error.response) {
                  if (error.response.data != undefined && error.response.data != null && error.response.data.error == true) {
                    errorType = error.response.data.errorType;
                    
                  }
                } else {
                  // Something happened in setting up the request that triggered an Error
                  errorType = "error";
                }

                window.history.replaceState({}, "Lyka", "/login");

                // On passe les states error à true
                this.setErrorType(errorType);
              });
            });
          }
          else { // AD
            this.setState({ loadingAuth: true }, () => {
              this.newConnexionAD().then(responseAD => {
                // Load session start link
                const startLink: string = sessionStorage.getItem(this.props.keyNameSessionLink);

                if (startLink != undefined && startLink != null) {
                  sessionStorage.removeItem(this.props.keyNameSessionLink);
                  this.setState({ loadingAuth: false }, () => {
                    this.props.history.push(startLink);
                  });
                }
                else {
                  this.setState({ loadingAuth: false }, () => {
                    this.props.history.push("/");
                  });
                }
              }).catch(error => {
                console.error(error);
                this.setState({ loadingAuth: false }, () => {
                  this.props.history.push("/");
                });
              });
            });
          }
        }).catch(error => {
          console.error("Error 'getParamYes' : ", error);
          Store.snackBar = {
            open: true,
            error: true,
            message: "Le serveur de connexion ne répond pas, veuillez contacter le support"
          };
          this.setErrorType("getParam");
        });
      }
      else {
        this.setState({loadingAuth: false});
      }
    }
  }

  componentDidUpdate(prevProps: LoginProps & RouteComponentProps, prevState: LoginState) {
    const errorParams: any = this.props.match.params;
    if (this.props.match.params != prevProps.match.params && errorParams.error != undefined && errorParams.error != null) {
      const errorType = errorParams.error;
      this.setErrorType(errorType);
    }
  }

  // Fonction pour passer les states d'erreur à true et remplir les bons message pour afficher la page d'erreur
  public setErrorType = (error: string) => {
    let errorType = "error";
    let errorMessage = "Une erreur est survenue lors de la connexion à l'outil";

    switch (error) {
      case "userNotFound":
        errorType = "userNotFound";
        errorMessage = "Compte non trouvé dans l'outil";
        break;
      case "userDesactivate":
        errorType = "userDesactivate";
        errorMessage = "Compte supprimé ou désactivé de l'outil";
        break;
      case "getParam":
        errorType = "getParam";
        errorMessage = "Le serveur de connexion ne répond pas, veuillez contacter le support";
        break;
      case "unknowToken":
        errorType = "unknowToken";
        errorMessage = "Erreur lors de la connexion, reconnexion en cours ...";
        break;

      default:
        errorType = "error";
        errorMessage = "Une erreur est survenue lors de la connexion à l'outil";
        break;
    }

    // On active les states pour afficher les erreurs sauf dans le cas du unknow token ou on reload l'auth
    if(errorType == "unknowToken") {
      this.setState({ loadingAuth: false }, () => {
        window.location.assign(Store.wsPath + '/1/authentication/azureadoauth2');
      });
    }
    else {
      this.setState({ error: true, errorType: errorType, errorMessage: errorMessage });
    }
  }

  public connexionAAD = () => {
    return new Promise<IUser>((resolve, reject) => {
      let ssoToken: any = "";
      if (cookie.load("token") == undefined && this.getParameterByName("ssoToken") == undefined) { // si aucun cookie et pas de param dans l'url redirection sur la connexion AAD
        window.location.assign(Store.wsPath + '/1/authentication/azureadoauth2');
      }
      else {
        if ((Store.userConnected == undefined || Store.userConnected.login == undefined) && cookie.load("token") != undefined) { // cookie existe mais store vide
          ssoToken = cookie.load("token", true);
        }
        else if ((Store.userConnected == undefined || Store.userConnected.login == undefined) && this.getParameterByName("ssoToken") != undefined) { // cookie n'existe pas, store vide mais param dans l'url présent
          ssoToken = this.getParameterByName("ssoToken");
        }

        if (ssoToken != "") { // ssoToken est rempli soit par le cookie soit par le param, on le vérifie
          axios.get(Store.wsPath + '/1/authentication/verifyToken', {
            headers: {
              Authorization: "Bearer l1Q7zkOL59cRqWBkQ12ZiGVW2DBL"
            }, params: { ssoToken: ssoToken }
          }).then((response) => {
            const data: any = jwt.decode(response.data.token); // user data
            cookie.remove("token");
            cookie.save('token', ssoToken, { path: '/' });

            resolve(data.user);
          }).catch(error => {
            console.error("Error 'verifyToken' : ", error);
            cookie.remove("token");
            //window.location.assign(Store.wsPath + '/1/authentication/azureadoauth2');

            reject(error);
          });
        }
        else {
          // Contenu déplacé au dessus
          reject("Pas de sso token");
        }
      }

    });
  }

  public newConnexionAD = () => {
    return new Promise<void>((resolve, reject) => {
      let ssoToken: any = "";
      if (cookie.load("token") == undefined && this.getParameterByName("ssoToken") == undefined) { // si aucun cookie et pas de param dans l'url redirection sur la connexion AAD
        window.location.assign(Store.wsPath + '/1/authentication/login?origin=' + window.location.origin);
      }
      else {
        if ((Store.userConnected == undefined || Store.userConnected.login == undefined) && cookie.load("token") != undefined) { // cookie existe mais store vide
          ssoToken = cookie.load("token", true);
        }
        else if ((Store.userConnected == undefined || Store.userConnected.login == undefined) && this.getParameterByName("ssoToken") != undefined) { // cookie n'existe pas, store vide mais param dans l'url présent
          ssoToken = this.getParameterByName("ssoToken");
        }

        if (ssoToken != "") { // ssoToken est rempli soit par le cookie soit par le param, on le vérifie
          axios.get(Store.wsPath + '/1/authentication/verifyToken', {
            headers: {
              Authorization: "Bearer l1Q7zkOL59cRqWBkQ12ZiGVW2DBL"
            }, params: { ssoToken: ssoToken }
          }).then((response) => {
            const data: any = jwt.decode(response.data.token); // user data
            cookie.remove("token");
            cookie.save('token', ssoToken, { path: '/' });
            this.setState({ loadingAuth: false });
            Store.userConnected = data.user;
            window.history.replaceState({}, "Lyka", "/");

            resolve();
          }).catch(error => {
            cookie.remove("token");
            window.location.assign(Store.wsPath + '/1/authentication/login');
            reject("error Auth" + error);
          });
        }
        else {
          // Contenu déplacé au dessus
          reject("Pas de sso token");
        }
      }

    });
  }

  public connexionLocal = () => {
    if ((Store.userConnected == undefined || Store.userConnected.login == undefined) && cookie.load("token") != undefined) {
      const token = cookie.load("token", true);
      axios.post(Store.wsPath + '/1/authentication/alreadyLogged', { token: token, redirectURL: "http://localhost:8080" }).then(response => {
        //  console.log("response.data", response.data)
        axios.get(Store.wsPath + '/1/authentication/verifyToken', {
          headers: {
            Authorization: "Bearer l1Q7zkOL59cRqWBkQ12ZiGVW2DBL"
          }, params: { ssoToken: response.data.ssoToken }
        }).then((res) => {
          //  console.log("response", res.data);
          const data: any = jwt.decode(res.data.token);
          // console.log("data", data)
          cookie.remove("token");
          cookie.save('token', res.data.token, { path: '/' });
          Store.userConnected = data.user;
        }).catch(error => console.error("error Auth", error))
      })
    }
    // else {
    //   if (this.getParameterByName("ssoToken") != undefined) {
    //     const token =this.getParameterByName("ssoToken")
    //     axios.get(Store.wsPath + '/1/authentication/verifyToken',{headers:{
    //         Authorization: "Bearer l1Q7zkOL59cRqWBkQ12ZiGVW2DBL"
    //     }, params:{ssoToken:token}}).then((response)=>{
    //         console.log("response",response.data);
    //         const data: any = jwt.decode(response.data.token);
    //         console.log("data",data)
    //         cookie.remove("token");
    //         cookie.save('token', response.data.token, { path: '/' });
    //         Store.userConnected = data.user;
    //     }).catch(error => console.log("error Auth",error))
    //   } else {
    //     // window.location.assign(Store.wsPath + '/1/authentication/login?urlSource=http://localhost:8080')
    //   }

    // }
    else {
      if (cookie.load("token", true) != undefined) {
        const token = this.getParameterByName("ssoToken")
        axios.get(Store.wsPath + '/1/authentication/verifyToken', {
          headers: {
            Authorization: "Bearer l1Q7zkOL59cRqWBkQ12ZiGVW2DBL"
          }, params: { ssoToken: token }
        }).then((response) => {
          //  console.log("response", response.data);
          const data: any = jwt.decode(response.data.token);
          //  console.log("data", data)
          cookie.remove("token");
          cookie.save('token', response.data.token, { path: '/' });
          Store.userConnected = data.user;
        }).catch(error => console.error("error Auth", error));
      } else {
        // window.location.assign(Store.wsPath + '/1/authentication/login?urlSource=http://localhost:8080')
      }

    }
  }

  public connexionAD = () => {
    if ((Store.userConnected == undefined || Store.userConnected.login == undefined) && cookie.load("token") != undefined) {
      const token = cookie.load("token", true);
      const data: any = jwt.decode(token)
      axios.get(Store.wsPath + '/1/users/GetUserInfo', { params: { login: data._json.sAMAccountName } }).then((response) => {
        Store.userConnected = response.data;
        /* axios.get(Store.wsPath + '/1/param/getParam')
        .then(responseParam => {
          if (responseParam.data > "") {
              Store.GeneralConfig.authorization.connect = responseParam.data.connect
              Store.GeneralConfig.authorization.modif = responseParam.data.modif
              Store.userRiskMenu.usersRiskList= responseParam.data.userRisk;
              const tempArray :boolean = responseParam.data.userRisk.find(test => test == Store.userConnected.id)
              if (tempArray) {
                Store.userRiskMenu.userRisk = true
              } else {
                Store.userRiskMenu.userRisk = false
              }
              if (Store.userRiskMenu.usersRiskList.length >0) {
                Store.parameters.viewParameter = false
                Store.parameters.viewEI = true
                Store.parameters.viewPAQ = false
                Store.iconStatus = {
                  ei: true,
                  settings: false,
                  paq: false
              }
              } else {
                Store.parameters.viewParameter = true
                Store.parameters.viewEI = false
                Store.parameters.viewPAQ = false
                Store.iconStatus = {
                  ei: false,
                  settings: true,
                  paq: false
              }
              }
          }
          cookie.remove("token");
          cookie.save('token', token, { path: '/' });
        }).catch(error =>{
          Store.snackBar.open = true
          Store.snackBar.error = true;
          Store.snackBar.message = "LYKA : L'identifiant n'existe pas, veuillez contacter le support"
  }) */
        cookie.remove("token");
        cookie.save('token', token, { path: '/' });
      }).catch(error => {
        Store.snackBar.open = true
        Store.snackBar.error = true;
        Store.snackBar.message = "LYKA : L'identifiant n'existe pas, veuillez contacter le support"
      })
    }
    else {
    }
  }

  public getParameterByName(name) {
    const url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
    const results = regex.exec(url);
    if (!results) return undefined;
    if (!results[2]) return undefined;
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  render() {
    return (
      <div>
        {
          this.state.error === true ?
            <Error errorTitle="Erreur Login" errorMessage={this.state.errorMessage} />
            :
            this.state.loadingAuth == true ?
              Store.funMode == true ?
                <div className={stylesheet.authLoadingContainer}>
                  <img className={stylesheet.loadingLogo} alt="Chargement en cours..." src="./images/unicornhead.png" />
                  Chargement en cours...
              </div>
                :
                <div className={stylesheet.authLoadingContainer}>
                  <LoadingLogo text="Chargement en cours..." />
                </div>
              :
              Store.authMode == "AAD" || Store.authMode == "AD" ?
                Store.funMode == true ?
                  <div className={stylesheet.authLoadingContainer}>
                    <img className={stylesheet.loadingLogo} alt="Chargement en cours..." src="./images/unicornhead.png" />
                    Reconnexion en cours, veuillez patienter...
                  </div>
                  :
                  <div className={stylesheet.authLoadingContainer}>
                    <LoadingLogo text="Reconnexion en cours, veuillez patienter..." />
                  </div>
                :
                <div>Page Login</div>
        }
      </div>
    );
  }

}

export default withRouter(Login);