import React, { Fragment } from 'react'
import { Route, RouteProps } from 'react-router-dom'
import { ContextualMenu } from '../Menus/ContextualMenu';
import MenuNavigationSharepoint from '../Menus/MenuNavigationSharepoint';
import CssBaseline from '@material-ui/core/CssBaseline';
import { observer } from "mobx-react";
import { IContextualMenu } from '../Menus/interfaces/IContextualMenu';
import Store from "../store/store";
const styles = require("../../styles/styles.module.scss");


interface ILayoutProps {
  menuContextuel: IContextualMenu;
  component: any;
  menuSharepoint: boolean;
}

interface ILayoutState {

}

@observer
export default class Layout extends React.Component<RouteProps & ILayoutProps, ILayoutState> {
  constructor(props: RouteProps & ILayoutProps) {
    super(props);
    this.state = {

    };
  }

  componentDidUpdate(prevProps:RouteProps & ILayoutProps, prevState:ILayoutState) {
    if(prevProps.path != this.props.path && this.props.path != "/document") {
      this.resetContextualMenu();
    }
  }

  private resetContextualMenu:() => void = () => {
    Store.contextualmenu = Store.defaultValueContextualMenu;
  }

  public onCloseContextualMenu: () => void = () => {
    if (this.props.menuContextuel.open == "extended" && this.props.menuContextuel.content == "ActionsWorkflow") { // Pour activer le menu contextuel réduit que sur certaines pages particulières
      Store.contextualmenu.open = "open";
    }
    else if (this.props.menuContextuel.open == "extended" && this.props.menuContextuel.content == "editDatedeFinsouhaitee") { // Pour activer le menu contextuel réduit si on ferme la modif de la date
      Store.contextualmenu = {
        open: "open",
        content: "ActionsWorkflow"
      };
    }
    else if (this.props.menuContextuel.open == "open" && this.props.menuContextuel.content == "ActionWorkflow") { // Pour activer le menu contextuel réduit que sur certaines pages particulières 
      Store.contextualmenu.open = "extended";
    }
    else if (this.props.menuContextuel.open == "extended") {
      Store.contextualmenu = Store.defaultValueContextualMenu;
    }
    else if (this.props.menuContextuel.open == "open") {
      Store.contextualmenu.open = "extended";
    }
  }

  render() {
    const { component: Component, menuContextuel, menuSharepoint, ...rest } = this.props;
    // const Component = this.props.component;
    let settings = {}
    let css: React.CSSProperties = {}

    // Cas pour le menu
    if (menuSharepoint == true && window.innerWidth > 768) {
      css.marginLeft = "255px";
    } else if (menuSharepoint == true && window.innerWidth > 768) {
      css.marginLeft = "5px";
    } else if (menuSharepoint == false && window.innerWidth > 768) {
      css.marginLeft = "75px";
    } else {
      css.marginLeft = "5px";
    }

    // Cas pour le menu contextuel
    if (menuContextuel.open == "open" && window.innerWidth > 768) {
      css.marginRight = "65px";
    }
    else if (menuContextuel.open == "extended" && window.innerWidth > 768) {
      css.marginRight = "280px";
    }
    else if (menuContextuel.open == "open" && window.innerWidth <= 768) {
      css.marginRight = "55px";
    }
    else if (menuContextuel.open == "extended" && window.innerWidth <= 768) {
      css.marginRight = "10px";
    }
    else {
      css.marginRight = "5px";
    }

    settings = { style: css }

    let cssMenuContextual: React.CSSProperties = {};

    if (menuContextuel.open == "extended" && menuContextuel.content == "viewModelWf") {
      cssMenuContextual = { right: 0, width: "550px" }
    }
    else if (menuContextuel.open == "hidden" && menuContextuel.content == "viewModelWf") {
      cssMenuContextual = { right: "-560px", width: "550px" }
    }
    else if (menuContextuel.open == "hidden" && menuContextuel.content != "viewModelWf") {
      cssMenuContextual = { right: "-310px", width: "256px" }
    }
    else if (menuContextuel.open == "extended" && menuContextuel.content != "viewModelWf") {
      cssMenuContextual = { right: 0, width: "256px" }
    }

    // test Thomas

    if (menuContextuel.open == "hidden") {
      cssMenuContextual = { right: "-310px", width: "256px" }
    }
    else if (menuContextuel.open == "open") {
      cssMenuContextual = { right: "-204px", width: "256px" }
    }
    else if (menuContextuel.open == "extended") {
      cssMenuContextual = { right: 0, width: "256px" }
    }
    return (
      <div>
        <CssBaseline />
        <div className={styles.content}>
          {/* <div className={styles.contentHeader}><Header /></div> */}
          <div className={styles.contentMenu}>
            <div className={styles.menuLeft}>
              <Fragment>
                <MenuNavigationSharepoint />
              </Fragment>
            </div>
          </div>
          {/* Pour réinitialiser les marges du menu au changement d ecrans */}
          <div {...settings} id={"contentComponentClose"} className={styles.contentComponent}>
            <Component {...rest} />
          </div>
          <div className={styles.contentContextual} style={cssMenuContextual}>
            <ContextualMenu onCloseContextualMenu={this.onCloseContextualMenu} menuOpen={menuContextuel.open} menuContent={menuContextuel.content} />
          </div>
        </div>
      </div>
    );
  }
}


// const Layout1 = observer(({ component: Component, ...rest }) => {
//   const { path, margin, menu, modeSharepoint, redirect, menuSharepoint } = rest;
//   let settings = {}
//   let css: React.CSSProperties = {}


//   if (margin && window.innerWidth > 768) {
//     css.marginLeft = "255px";
//   } else if (margin && window.innerWidth <= 768) {
//     css.marginLeft = "5px";
//   } else if (!margin && window.innerWidth > 768) {
//     css.marginLeft = "75px";
//   } else {
//     css.marginLeft = "5px";
//   }

//   if (Store.menuSharepoint.menuSharepointOpen == true && window.innerWidth > 768) {
//     css.marginLeft = "255px";
//   } else if (Store.menuSharepoint.menuSharepointOpen == true && window.innerWidth > 768) {
//     css.marginLeft = "5px";
//   } else if (Store.menuSharepoint.menuSharepointOpen == false && window.innerWidth > 768) {
//     css.marginLeft = "75px";
//   } else {
//     css.marginLeft = "5px";
//   }

//   if (menu == "open" && window.innerWidth > 768) {
//     css.marginRight = "65px";
//   }
//   else if (menu == "extended" && window.innerWidth > 768) {
//     css.marginRight = "280px";
//   }
//   else if (menu == "open" && window.innerWidth <= 768) {
//     css.marginRight = "55px";
//   }
//   else if (menu == "extended" && window.innerWidth <= 768) {
//     css.marginRight = "10px";
//   }
//   else {
//     css.marginRight = "5px";
//   }

//   settings = { style: css }

//   //  style={menu ? { right: 0 } : { right: "-310px" }}

//   let cssMenuContextual: React.CSSProperties = {};

//   if (menu == "extended" && Store.contextualmenu.content == "viewModelWf") {
//     cssMenuContextual = { right: 0, width: "550px" }
//   }
//   else if (menu == "hidden" && Store.contextualmenu.content == "viewModelWf") {
//     cssMenuContextual = { right: "-560px", width: "550px" }
//   }
//   else if (menu == "hidden" && Store.contextualmenu.content != "viewModelWf") {
//     cssMenuContextual = { right: "-310px", width: "256px" }
//   }
//   else if (menu == "extended" && Store.contextualmenu.content != "viewModelWf") {
//     cssMenuContextual = { right: 0, width: "256px" }
//   }

//   // test Thomas

//   if (menu == "hidden") {
//     cssMenuContextual = { right: "-310px", width: "256px" }
//   }
//   else if (menu == "open") {
//     cssMenuContextual = { right: "-204px", width: "256px" }
//   }
//   else if (menu == "extended") {
//     cssMenuContextual = { right: 0, width: "256px" }
//   }

//   return (
//     <Route {...rest} render={matchProps => (
//       <div>
//         <CssBaseline />
//         <div className={styles.content}>
//           {/* <div className={styles.contentHeader}><Header /></div> */}
//           <div className={styles.contentMenu}>
//             <div className={styles.menuLeft}>
//               <Fragment>
//                 <MenuNavigationSharepoint />
//               </Fragment>
//             </div>
//           </div>
//           {/* Pour réinitialiser les marges du menu au changement d ecrans */}
//           <div {...settings} className={styles.contentComponent}>
//             <Component {...matchProps} redirect={redirect} />
//           </div>
//           {/* DIV> globale pour fermer le menu quand on clique aillerus TODO voir plus tard <div className={styles.contextualMenuBis}>
//             {menu ?
//             <div className={styles.contentMenuContainer} style={ menu ? {display : 'block'}:{display : 'none'}} onClick = {()=>{Store.contextualmenu.open = "hidden"}}>
//             </div>
//               : ""}
//               {menu ? <ContextualMenu />: ""}
//           </div> */}
//           <div className={styles.contentContextual} style={cssMenuContextual}>
//             <ContextualMenu />
//           </div>
//           {/*  <div className = {styles.contextualMenuContainer}>
//             {menu ? <ContextualMenu visible:menu/>: ""}
//           </div> */}
//         </div>
//       </div>
//     )} />
//   )
// });
// export default Layout