import * as React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import Checkbox from '@material-ui/core/Checkbox';
import Collapse from '@material-ui/core/Collapse';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import 'simplebar/dist/simplebar.min.css';
const styles = require('./styles/ListtModels_styles.module.scss');
import Store, { getAllRoles } from '../store/store';
import axios from 'axios';
import { observer } from "mobx-react";
import Modal from "@material-ui/core/Modal/Modal";
import { IModelWf, IStepsModel, ITypeForm } from '../Workflow/interfaces/IWorkflow';
const modal = require("../../styles/styles.module.scss");
import TreeMenu from 'react-simple-tree-menu';
import Grid from '@material-ui/core/Grid';
import FileExplorer from "../FileExplorer/FileExplorer";
import Spinner from "../Spinner/Spinner";
import { IRole } from '../parametersRoles/interfaces/IRole';
import { constructTree } from '../helper/helpers';


interface Props { // Les props font passer les éléments entre composant
    reload: string; // Valeur possible : "no", "all" ou "model";
}

interface State {
    modelSelected?: IModelWf,
    elAnchor?: any;
    elAnchorModel?: any,
    openActionMenu?: boolean,
    indexStep: number,
    menuActionType: string,
    myStepSelectionned?: IStepsModel,
    typeDelete?: string,
    openActionModel?: boolean,
    treeModeles: any[];
    selectedItemFileExplorer?: any;
    showFileExplorer?: boolean;
    isnotified?: boolean

    typeModelSelected: string;

    allModelsList: IModelWf[];
    allTypeForm:ITypeForm[];
    modalIsOpen: boolean;
    allStepModel: any[];

    loadingSteps: boolean;
    loadingButtonModal: boolean;
}

@observer
export default class ListModelWf extends React.Component<Props, State>{

    public constructor(props: Props) { // Dans le constructeur, on initialise toujours les states
        super(props)
        this.state = {
            modelSelected: undefined,
            elAnchor: null,
            elAnchorModel: null,
            openActionMenu: false,
            openActionModel: false,
            indexStep: 0,
            menuActionType: "",
            typeDelete: '',
            treeModeles: [{ key: "Chargement des modèles en cours", label: "Chargement des modèles en cours", name: "Chargement des modèles en cours", id: "noModel" }],
            selectedItemFileExplorer: null,
            showFileExplorer: false,
            isnotified: false,
            typeModelSelected: "lyflow",
            allModelsList: [],
            allTypeForm:[],
            modalIsOpen: false,
            allStepModel: [],
            loadingSteps: true,
            loadingButtonModal: false
        }

        Store.modelWf = Store.defaultValueModelWf;
    }


    componentDidMount() {
        const searchInput = document.getElementsByClassName("rstm-search")[0];
        if (searchInput != null) {
            searchInput.setAttribute('placeholder', "Rechercher un modèle");
        }
        this.getAllModelsWf();
    }

    async componentDidUpdate(prevProps: Props, prevState: State) {
        if (this.props.reload !== prevProps.reload && this.props.reload === "all") {
            Store.reloadListModel = "no";

            // Check si les roles sont vides et si c'est le cas les récupérer pour mettre à jour
            if (Store.allRoles.length == 0) {
                try {
                    await getAllRoles();
                } catch (error) {
                }
            }
            this.onClickItem(Store.modelWf.id);
            this.getAllModelsWf();

            // Reset des store utilisé dans le menu
            Store.modelWf = Store.defaultValueModelWf;
            Store.allModelWf = [];
            Store.allTypeForm = [];
        } else if (this.props.reload !== prevProps.reload && this.props.reload === "model") {
            Store.reloadListModel = "no";
            this.onClickItem(this.state.modelSelected.id);

            // Reset des store utilisé dans le menu
            Store.modelWf = Store.defaultValueModelWf;
            Store.allModelWf = [];
            Store.allTypeForm = [];
            Store.stepModel = undefined;
        }
    }

    // Fonction pour construire l'arborescence des modèles
    // public testTree = async (modeles: any[],typesForm:ITypeForm[]) => {
    //     const modelesParent: any[] = modeles.filter(modele => modele.idParent == null);
    //     const testResult: any = [];
    //     if (modelesParent.length > 0) {
    //         for (const modeleParent of modelesParent) {
    //             const children = await this.getChildren(modeleParent, modeles);
    //             testResult.push({ key: modeleParent.id.toString(), id: modeleParent.id, label: modeleParent.name, nodes: children, isGRC: modeleParent.isGRC, isForm: modeleParent.isForm, formType:modeleParent.formType });
    //         }
    //         this.setState({ treeModeles: testResult, allModelsList: modeles,allTypeForm:typesForm });
    //     }
    //     else {
    //         testResult.push({ key: "Pas de modèle pour le moment", label: "Pas de modèle pour le moment", name: "Pas de modèle pour le moment", id: "noModel" });
    //         this.setState({ treeModeles: testResult, allModelsList: [], allTypeForm:[] });
    //     }
    // }

    // // Fonction de récurssivitée pour construire l'arbre des modèles
    // public getChildren = async (modeleParent, allModeles: any[]) => {
    //     const treeChildren: any[] = [];
    //     const children: any[] = allModeles.filter(modele => modele.idParent == modeleParent.id);
    //     for (const child of children) {
    //         const usersDirectReports = await this.getChildren(child, allModeles);
    //         // usersDirectReports ? treeChildren.push({ name: child.name, id: child.id, idParent: child.idParent, children: usersDirectReports }) :
    //         //     treeChildren.push({ title: child });

    //         usersDirectReports ? treeChildren.push({ key: child.id.toString(), id: child.id, label: child.name, nodes: usersDirectReports, isGRC: child.isGRC, isForm: child.isform, formType: child.formType }) :
    //             treeChildren.push({ title: child });
    //     }
    //     return treeChildren;
    // }

    // Fonction qui récupére tous les modéles
    public getAllModelsWf = () => {
        axios.get(Store.wsPath + `/1/workflows/getAllModelWf`).then(response => {
            axios.get(Store.wsPath + `/1/formulaire/GetAllFormType`).then(resType => {
                constructTree(response.data,resType.data).then(tree => {
                    this.setState(tree);
                })
            })
        }).catch(error => {
            console.error("Error 'getAllModelsWf' : ", error);
            // SnackBar
            Store.snackBar.message = "Une erreur est survenue lors de la récupération de la liste des modèles, réessayez";
            Store.snackBar.error = true;
            Store.snackBar.open = true;
        });
    }

    modalOpen = (typeDelete: string) => {
        this.setState({
            typeDelete: typeDelete,
            modalIsOpen: true
        });
        this.closeMenuModel();
    }

    modalClose = () => {
        this.setState({ modalIsOpen: false });
    }

    onNewModelWf = () => {
        Store.contextualmenu.content = "addNewModel";
        Store.contextualmenu.open = "extended";

        // Initialisation nécessaire pour le menu
        Store.allModelWf = this.state.allModelsList;
        Store.allTypeForm = this.state.allTypeForm;
    }

    public viewModelWf = (id: number | string) => {
        Store.contextualmenu = Store.defaultValueContextualMenu;

        axios.get(Store.wsPath + '/1/workflows/getModelWfById', {
            params: {
                idModel: id
            }
        }).then(response => {
            const selectedModel = response.data;

            if (selectedModel != undefined && selectedModel != null && selectedModel.id != undefined) {
                this.setState({
                    showFileExplorer: selectedModel.pathRapatriement == null && selectedModel.pathRapatriement == undefined ? true : false,
                    selectedItemFileExplorer: selectedModel.pathRapatriement == null && selectedModel.pathRapatriement == undefined ? {} : typeof (selectedModel.pathRapatriement) == "string" ? JSON.parse(selectedModel.pathRapatriement) : selectedModel.pathRapatriement,
                    modelSelected: selectedModel,
                    isnotified: selectedModel.isNotified != undefined && selectedModel.isNotified != null ? selectedModel.isNotified : false
                }, () => {
                    this.getAllStepsModel();
                });
            }
            else {
                // SnackBar
                Store.snackBar.message = "Erreur lors de la récupération du modèle, réessayez";
                Store.snackBar.error = true;
                Store.snackBar.open = true;
                this.setState({ loadingSteps: false });
            }
        }).catch(error => {
            console.error("Error 'getModelWfById' : ", error);
            // SnackBar
            Store.snackBar.message = "Erreur lors de la récupération du modèle, réessayez";
            Store.snackBar.error = true;
            Store.snackBar.open = true;
            this.setState({ loadingSteps: false });
        });
    }

    public closeMenuAction = () => {
        this.setState({ openActionMenu: false, elAnchor: null, indexStep: 0 });
    }

    public closeMenuModel = () => {
        this.setState({ openActionModel: false, elAnchorModel: null });
    }

    public editStep = (stepIndex, myStepSelectionned: IStepsModel, event) => {
        Store.contextualmenu = Store.defaultValueContextualMenu;
        event.preventDefault();
        Store.stepModel = {
            name: myStepSelectionned.name,
            id: myStepSelectionned.id,
            state: myStepSelectionned.state,
            rankStep: myStepSelectionned.rankStep,
            idxModel: myStepSelectionned.idxModel,
            idxStatut: myStepSelectionned.idxStatut,
            idStatutForm:myStepSelectionned.idStatutForm,
            idsRole: myStepSelectionned.idsRole,
            idsRoleSecondaire: myStepSelectionned.idsRoleSecondaire,
            typeTask: myStepSelectionned.typeTask,
            isRequired: myStepSelectionned.isRequired,
            isStepOrientation: myStepSelectionned.isStepOrientation,
            idsStepOrientationModel: myStepSelectionned.idsStepOrientationModel,
            isNotified: myStepSelectionned.isNotified,
            realNbDays: myStepSelectionned.realNbDays,
            changeStatut: myStepSelectionned.changeStatut,
            isCollab: myStepSelectionned.isCollab,
            isAllowApprop: myStepSelectionned.isAllowApprop,
            isCreateForm: myStepSelectionned.isCreateForm,
            createFormType: myStepSelectionned.createFormType,
            isCreateFDP: myStepSelectionned.isCreateFDP,
            isConvocation: myStepSelectionned.isConvocation,
            isResponse:myStepSelectionned.isResponse,
            responseModelParentId:myStepSelectionned.responseModelParentId
        };

        // On rempli le Store Model pour avoir accés aux infos du modèle dans le menu contextuel
        Store.modelWf = this.state.modelSelected;

        this.setState({ openActionMenu: false }, () => {
            Store.contextualmenu.content = "editStepModel";
            Store.contextualmenu.open = "extended";
        });
    }

    public addStep = (step: IStepsModel) => {
        Store.contextualmenu.content = "insertStepModel"
        Store.contextualmenu.open = "extended";
        Store.stepModel = {
            name: "",
            id: step.id,
            idxModel: step.idxModel,
            state: step.state,
            rankStep: step.rankStep,
            idsRole: [],
            isStepOrientation: false, // Par défaut l'étape d'orientation est décochée
            idsRoleSecondaire: [],
            isNotified: true, // Par défaut , le paramètre de mail doit être a oui
            isCollab: false,
            isConvocation: false,
            isResponse:false,
            responseModelParentId:null
        };

        // On rempli le Store Model pour avoir accés aux infos du modèle dans le menu contextuel
        Store.modelWf = this.state.modelSelected;
    }


    public onDeleteModel = () => {
        this.setState({ loadingButtonModal: true }, () => {
            axios.post(Store.wsPath + '/1/workflows/disableModelByIdModel', {
                idxModel: this.state.modelSelected.id,
                modeSharePoint: Store.modeSharepoint
            }).then(() => {
                this.getAllModelsWf();
                this.modalClose();
                this.setState({
                    modelSelected: undefined,
                    allStepModel: [],
                    loadingButtonModal: false
                });

                Store.snackBar.message = "Modèle supprimé avec succès";
                Store.snackBar.open = true;
            }).catch(error => {
                console.error("Error 'onDeleteModel' : ", error);
                Store.snackBar.message = "Une errreur est survenue lors de la suppression du modèle";
                Store.snackBar.error = true;
                Store.snackBar.open = true;
                this.setState({ loadingButtonModal: false });
            });
        });
    }

    public openMenuAction = (indexStep, step, type) => event => {
        this.setState({ elAnchor: event.currentTarget, openActionMenu: true, indexStep: indexStep, menuActionType: type, myStepSelectionned: step });
    }

    public deleteStep = (stepIndex, event) => {
        event.preventDefault();
        this.modalOpen('deleteStep');
        this.setState({ openActionMenu: false });
    }

    public editModel = () => {
        Store.contextualmenu.content = "editModel"
        Store.modeEditionModel = true;
        Store.contextualmenu.open = "extended"

        // Initialisation nécessaire pour le menu
        Store.modelWf = this.state.modelSelected;
        Store.allModelWf = this.state.allModelsList;
        Store.allTypeForm = this.state.allTypeForm;

        // Pour refermer le menu après le click
        this.closeMenuModel();
    }

    public openMenuModel = (event) => {
        this.setState({ elAnchorModel: event.currentTarget, openActionModel: true });
    }

    // Fonction pour afficher les modèles enfant possible lors d'une étape d'orientation
    displayModelInOrientation = (isStepOrientation: boolean, modelId: number) => {
        let resultModel: JSX.Element = <React.Fragment></React.Fragment>;
        if (isStepOrientation == true) {
            const findAllChildrenModel: any[] = this.state.allModelsList.filter(model => model.idParent == modelId);
            if (findAllChildrenModel.length > 0) {
                resultModel = (<div className={styles.contentModelStepOrientation}>
                    {findAllChildrenModel.map((m, index) => {
                        const nameModel = m.name != undefined && m.name != null ? findAllChildrenModel.length - 1 != index ? m.name + " | ": m.name : "";
                        return (
                            <span key={m + "*" + index}>{nameModel}</span>
                        )
                    })}
                </div>);
            }
        }

        return resultModel;
    }

    // Fonction qui affiche les étapes du modèle sélectionné
    public viewmodelRender = () => {
        return (
            <React.Fragment>
                <div>
                    <div className={styles.contentStep}>
                        <div>Paramétrage du modèle : {this.state.modelSelected.name}</div>
                        <span onClick={this.openMenuModel} className={styles.iconContainerStep}><Icon title="Action">more_horiz</Icon></span>
                        <Menu
                            id="model-menu"
                            anchorEl={this.state.elAnchorModel}
                            open={this.state.openActionModel}
                            onClose={this.closeMenuModel}
                        >
                            <div>
                                <MenuItem key="Modifier" title="Modifier le modèle" onClick={this.editModel}>
                                    Modifier
                                </MenuItem>

                                <MenuItem key="Supprimer" title="Supprimer le modèle" onClick={() => { this.modalOpen('deleteModel') }}>
                                    Supprimer
                                </MenuItem>
                            </div>

                        </Menu>
                    </div>

                    {this.state.loadingSteps == true ?
                        <Spinner style={{ padding: "5px" }} labelPlacement="right" label="Chargement en cours..." size={20} />
                        :
                        this.state.allStepModel != undefined && this.state.allStepModel.length > 0 ?
                            this.state.allStepModel.sort((el1, el2) => el1.rankStep - el2.rankStep).map((step, index) => {
                                return (
                                    <div key={step.name + "-" + index}>
                                        <div className={styles.stepAndRolesContainer}>
                                            <div className={styles.nameStep}>
                                                <span className={styles.span}>{step.name}</span>
                                                <span onClick={this.openMenuAction(index, step, 'step')} className={styles.iconContainerStep}>
                                                    <Icon title="Action">more_horiz</Icon>
                                                </span>
                                            </div>

                                            <div style={{ background: "#e2e2e2", padding: "3px 20px", display: "flex", flexWrap: "wrap" }}>
                                                {step.idsRole != null && step.idsRole != undefined && step.idsRole.length > 0 ?
                                                    step.idsRole.map((role, index: number) => {
                                                        const tempRole: IRole = Store.allRoles.find(roleDeLaListe => roleDeLaListe.id == role);
                                                        let roleName = "";
                                                        let roleUser = "";
                                                        if (tempRole != undefined) {
                                                            roleName = tempRole.name;
                                                            const email = tempRole.User != null && tempRole.User != undefined ? " <" + tempRole.User.email + ">" : ""
                                                            roleUser = tempRole.user + email;
                                                        }

                                                        return (
                                                            <p title={roleUser} key={roleName + "_" + index} style={{ cursor: "pointer", color: "black", margin: 0, paddingRight: '16px' }}>{roleName}</p>
                                                        )
                                                    })
                                                    :
                                                    <React.Fragment></React.Fragment>
                                                }
                                                {step.idsRoleSecondaire != undefined && step.idsRoleSecondaire != null && step.idsRoleSecondaire.length > 0 ?
                                                    step.idsRoleSecondaire.map((role: number) => {
                                                        const tempRole: IRole = Store.allRoles.find(roleDeLaListe => roleDeLaListe.id == role);
                                                        let roleName = "";
                                                        let roleUser = "";
                                                        if (tempRole != undefined) {
                                                            roleName = tempRole.name;
                                                            const email = tempRole.User != null && tempRole.User != undefined ? " <" + tempRole.User.email + ">" : "";
                                                            roleUser = tempRole.user + email;
                                                        }

                                                        return (
                                                            <p title={roleUser} key={roleName + "/" + role} style={{ cursor: "pointer", color: "orange", margin: 0, paddingRight: '16px' }}>{roleName}</p>
                                                        )
                                                    })
                                                    :
                                                    <React.Fragment></React.Fragment>
                                                }

                                            </div>
                                            <div>
                                                {this.displayModelInOrientation(step.isStepOrientation, step.idxModel)}
                                            </div>
                                        </div>
                                        <div className={styles.iconModel}>
                                            {this.state.allStepModel.length - 1 != index ?
                                                <div className={styles.contentIconArrow}>
                                                    <Icon title="" className={styles.iconArrow}>expand_more</Icon>
                                                </div>
                                                : ""
                                            }
                                            <div className={styles.addStepIconContainer}><Icon onClick={() => this.addStep(step)} title={this.state.allStepModel.length - 1 != index ? "Insérer une étape" : "Ajouter une nouvelle étape"} className={styles.addStepIcon}>add</Icon></div>
                                        </div>
                                    </div>
                                )
                            }
                            )
                            :
                            ""
                    }
                </div>


                <Menu
                    id="long-menu"
                    anchorEl={this.state.elAnchor}
                    open={this.state.openActionMenu}
                    onClose={this.closeMenuAction}
                >
                    <div>
                        <MenuItem key="Modifier" title="Modifier l'étape" onClick={() => { this.editStep(this.state.indexStep, this.state.myStepSelectionned, event) }}>
                            Modifier
                        </MenuItem>

                        <MenuItem key="Supprimer" title="Supprimer l'étape" onClick={() => { this.deleteStep(this.state.indexStep, event) }}>
                            Supprimer
                        </MenuItem>
                    </div>

                </Menu>
            </React.Fragment>
        )
    }

    public getAllStepsModel = () => {
        this.setState({ loadingSteps: true }, () => {
            axios.get(Store.wsPath + '/1/workflows/getAllStepsByIdModel', {
                params: {
                    idModel: this.state.modelSelected.id
                }
            }).then(response => {
                this.setState({ allStepModel: response.data, loadingSteps: false });
            }).catch(error => {
                console.error("Error 'getAllStepsModel' : ", error);
                // SnackBar
                Store.snackBar.message = "Erreur lors de la récupération des étapes du modèle, réessayez";
                Store.snackBar.error = true;
                Store.snackBar.open = true;
                this.setState({ loadingSteps: false });
            });
        });
    }

    public onDeleteStep = () => {
        this.setState({ loadingButtonModal: true }, () => {
            axios.post(Store.wsPath + '/1/workflows/deleteStepModelById', {
                idStepModel: this.state.myStepSelectionned.id,
                rankStep: this.state.myStepSelectionned.rankStep,
                idModel: this.state.myStepSelectionned.idxModel
            }).then(() => {
                this.modalClose();
                this.getAllStepsModel();
                // SnackBar
                Store.snackBar.message = "L'étape supprimée avec succès";
                Store.snackBar.open = true;
                this.setState({ loadingButtonModal: false });
            }).catch(error => {
                console.error("Error 'onDeleteStep' : ", error);
                // SnackBar
                Store.snackBar.message = "Erreur lors de la suppression de l'étape, réessayez";
                Store.snackBar.error = true;
                Store.snackBar.open = true;
                this.setState({ loadingButtonModal: false });
            });
        });
    }

    public onClickItem = (nodes) => {
        let id: number | string = "noModel";
        if (nodes.id != undefined) {
            id = nodes.id
        } else {
            id = nodes
        }

        if (nodes.id != "noModel") {
            // this.setState({ showFileExplorer: false, modelSelectedId: id });
            this.viewModelWf(id);
        }
    }

    public findItemInTreeModelForSelect = (idModelSelected: number, allModelsData: any[]) => {
        let selectedModel: string = "";
        if (allModelsData != undefined && allModelsData.length > 0) {
            allModelsData.forEach(model => {
                if (model.id == idModelSelected) {
                    selectedModel = model.key;
                }
                else {
                    if (model.nodes != undefined && model.nodes.length > 0) {
                        const parent = model.key;
                        selectedModel = this.findItemInTreeModelForSelectChildren(idModelSelected, model.nodes, parent, selectedModel);
                    }
                }
            });
        }
        else {
            selectedModel = "";
        }
        return selectedModel;
    }


    public findItemInTreeModelForSelectChildren = (idModelSelected: number, allModelsData: any[], parentKey: string, selectedModel: string): string => {
        // let selectedModel:string = "";
        if (allModelsData != undefined && allModelsData.length > 0) {
            allModelsData.forEach(model => {
                if (model.id == idModelSelected) {
                    selectedModel = parentKey + "/" + model.key;
                }
                else {
                    if (model.nodes != undefined && model.nodes.length > 0) {
                        const parent = parentKey + "/" + model.key;
                        selectedModel = this.findItemInTreeModelForSelectChildren(idModelSelected, model.nodes, parent, selectedModel);
                    }
                }
            });
            return selectedModel;
        }
        else {
            return selectedModel;
        }
    }

    public onChange = (name: string) => event => {
        if (name == "gestionPJ") {
            const selectedModel = this.state.modelSelected;
            selectedModel.isSavedPJ = event.target.value;
            this.setState({ modelSelected: selectedModel });
        } else if (name == "pathRapatriement") {
            let value = null;
            if (event.length > 0) {
                value = event[0];
            }
            else {
                value = null;
            }
            this.setState({ selectedItemFileExplorer: value });
        } else if (name == "gestionNotifs") {
            // Store.modelWf.isNotified = event.target.value
            this.setState({
                isnotified: event.target.checked
            });
        }

    }

    public onChangeCb = () => event => {
        this.setState({
            isnotified: event.target.checked
        })
    }


    // Fonction pour mettre à jour le lien de rapatriement des piéces jointes
    public updateGestionPiecesJointes = () => {
        if (this.state.modelSelected.isSavedPJ != "no" && (this.state.selectedItemFileExplorer == undefined || this.state.selectedItemFileExplorer == null || this.state.selectedItemFileExplorer.key == undefined) && this.state.typeModelSelected != "form") {
            Store.snackBar.open = true
            Store.snackBar.error = true
            Store.snackBar.message = "Vous devez choisir une bibliothèque ou un dossier de destination"
        }
        else {
            const idModel = this.state.modelSelected.id;
            let myObjectToJSON = null;
            if (this.state.modelSelected.isSavedPJ != "no") {
                myObjectToJSON = { ...this.state.selectedItemFileExplorer };

                myObjectToJSON = JSON.stringify(myObjectToJSON);
            }

            axios.post(Store.wsPath + '/1/workflows/updateGestionPiecesJointes', {
                idModel: idModel,
                isSavedPJ: this.state.modelSelected.isSavedPJ,
                pathRapatriement: myObjectToJSON,
                isMailNotified: this.state.isnotified
            }).then((response) => {
                if (response.status == 200) {
                    Store.snackBar.open = true
                    Store.snackBar.error = false
                    Store.snackBar.message = "Vos PJ seront rapatriées à l'emplacement choisi sur les circuits basé sur ce modèle"

                    this.viewModelWf(this.state.modelSelected.id);
                }
            })
        }
    }

    public updateNotifMail = () => {
        axios.post(Store.wsPath + '/1/workflows/updateNotifMail', {

        }).then((response => {
            if (response.status == 200) {
                Store.snackBar.open = true
                Store.snackBar.error = false
                Store.snackBar.message = " Vos paramètres de gestion des notifications e-mail ont été mis à jour."
            }
        }))
    }

    // Fil d'ariane pour l'explorateur de fichier
    public breadCrumbs = (itemSelect: any | string, text?: string, separator?: string) => {
        let itemSelected = null
        if (typeof (itemSelect) == "string") {
            itemSelected = JSON.parse(itemSelect);
        }
        else {
            itemSelected = itemSelect
        }
        const container = [];
        const textBefore: string = text != undefined && text != null ? text : "Sélection :";
        const separatorChar: string = separator != undefined && separator != null ? separator : " / ";

        container.push(<span>{textBefore}</span>);

        if (itemSelected.key != undefined && itemSelected.key != null) {
            const item: any = itemSelected;
            switch (item.type) {
                case "folder":
                    const folderSplit: string[] = item.path.split(item.libraryPath)[1].split("/").filter(folderName => folderName.length > 0);
                    container.push(<React.Fragment><span key={item.siteName + "breadCrumbs"}>{item.siteName}</span><span key={item.libraryName + "separator"}>{separatorChar}</span><span key={item.libraryName + "breadCrumbs"}>{item.libraryName}</span></React.Fragment>);
                    folderSplit.forEach((folderName: string, index: number) => {
                        container.push(<React.Fragment><span key={folderName + "separator" + index}>{separatorChar}</span><span key={folderName + "breadCrumbs"}>{folderName}</span></React.Fragment>);
                    });
                    break;
                case "library":
                    container.push(<React.Fragment><span key={item.siteName + "breadCrumbs"}>{item.siteName}</span><span key={item.libraryName + "separator"}>{separatorChar}</span><span key={item.libraryName + "breadCrumbs"}>{item.libraryName}</span></React.Fragment>);
                    break;
                default:
                    container.push(<React.Fragment><span key={item.label + "breadCrumbs"}>{separatorChar}</span><span>{item.label}</span></React.Fragment>);
                    break;
            }
        }

        return container
    }

    // Fonction pour fabriquer le lien de clicpour la bibliothéque de document choisi pour le rapatriement
    public makeUrlForPieceJointe: (item: any | string) => string = (item: any) => {
        let result: string = "#";
        if (item != null && item != undefined) {
            let itemParse = null;
            if (typeof (item) == "string") {
                itemParse = JSON.parse(item);
            }
            else {
                itemParse = item
            }
            const splitArray = itemParse.sitePath.split("/");
            result = splitArray[0] + "//" + splitArray[2] + itemParse.path;
        }
        return result;
    }

    public openCollapseFileExplorer = () => {
        this.setState({ showFileExplorer: !this.state.showFileExplorer });
    }

    public onChangeTypeModelsList = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ typeModelSelected: event.target.value, modelSelected: undefined, allStepModel: [] });
    }

    public render() {
        return (
            <div>
                <Modal
                    aria-describedby="simple-modal-description"
                    open={this.state.modalIsOpen}
                    onClose={this.modalClose}
                    className={modal.modal}
                    disableEnforceFocus={true}
                >
                    <div className={modal.modalContent}>
                        <Typography variant="h5" id="modal-title">
                            Suppression
                        </Typography>
                        <br />
                        <Typography variant="subtitle2" id="simple-modal-description">
                            {this.state.typeDelete == 'deleteModel' ?
                                "Vous allez supprimer ce modèle de circuit, êtes vous sûr(e)s de cette action" : ""}
                            {this.state.typeDelete == 'deleteStep' ?
                                "Vous allez supprimer cette étape du modèle, êtes vous sûr(e)s de cette action" : ""}
                        </Typography>
                        <br />
                        <div className={modal.modalButton}>
                            <Button
                                onClick={this.modalClose}
                                className={modal.button}
                                variant="contained"
                                color="secondary"
                            >
                                Annuler
                            </Button>
                            <Button
                                className={modal.button}
                                onClick={this.state.typeDelete == 'deleteModel' ? this.onDeleteModel : this.onDeleteStep}
                                variant="contained"
                                color="primary"
                                disabled={this.state.loadingButtonModal == true ? true : false}
                            >
                                {this.state.loadingButtonModal == true ?
                                    <Spinner size={24} />
                                    :
                                    "Valider"
                                }
                            </Button>

                        </div>
                    </div>
                </Modal>
                <div className={styles.firstContent}>
                    <div className={styles.modelsTitle} style={{ background: Store.themeParam.backgroundPrimary }}><h5>Liste des modèles :</h5></div>
                    <div className={styles.contentbodyModel}>
                        <Button onClick={this.onNewModelWf} variant="contained" color="primary"><Icon>add</Icon>Ajouter un modèle de circuit</Button>
                        {this.state.allTypeForm.length >0 ?
                          <FormControl style={{ width: "100%", margin: "15px 0 0 0" }} component="fieldset">
                            <FormLabel component="legend">Modèles à afficher : </FormLabel>
                            <RadioGroup row={true} aria-label="Modèles à afficher" name="typeModels" value={this.state.typeModelSelected} onChange={this.onChangeTypeModelsList}>
                                <FormControlLabel value="lyflow" control={<Radio />} label="Lyflow" />
                                {this.state.allTypeForm.map((formType)=>{
                                  return(
                                    <FormControlLabel value={formType.name} control={<Radio />} label={formType.label} />
                                  )
                                })}
                                
                            </RadioGroup>
                          </FormControl>
                          :
                          <React.Fragment/>
                        } 
                        <Grid container spacing={2} className={styles.spacingGrid}>
                            <Grid item lg={6} sm={6} xs={12}>
                                <TreeMenu data={this.state.typeModelSelected == "lyflow" ? this.state.treeModeles.filter(model => model.isGRC != true && model.isForm != true) : this.state.treeModeles.filter(model => model.isForm == true && model.formType == this.state.typeModelSelected)}
                                    onClickItem={this.onClickItem}
                                    activeKey={this.state.modelSelected != undefined && this.state.modelSelected != null ? this.findItemInTreeModelForSelect(this.state.modelSelected.id, this.state.treeModeles) : "''"}
                                    focusKey={this.state.modelSelected != undefined && this.state.modelSelected != null ? this.findItemInTreeModelForSelect(this.state.modelSelected.id, this.state.treeModeles) : "''"}
                                />
                            </Grid>

                            {this.state.modelSelected != undefined && this.state.modelSelected.id != undefined ?
                                <Grid item lg={6} sm={6} xs={12}>
                                    {this.viewmodelRender()}
                                </Grid>
                                :
                                ""
                            }
                        </Grid>
                    </div>
                </div>
                <div className={styles.secondContent}>
                    {this.state.modelSelected && this.state.modelSelected.id != undefined ?
                        <>
                            <div className={styles.modelsTitle} style={{ background: Store.themeParam.backgroundPrimary }}> <h5>Paramétrage :</h5></div>
                            <div className={styles.contentbodyModel}>
                                {this.state.typeModelSelected == "lyflow" ?
                                    <RadioGroup
                                        name={"Gestion des PJ"}
                                        onChange={this.onChange("gestionPJ")}
                                    >
                                        <div>
                                            <p>Gestion des pièces jointes de {this.state.modelSelected.name}</p>
                                            <FormControlLabel
                                                checked={this.state.modelSelected.isSavedPJ == "no" ? true : false}
                                                value="no"
                                                control={<Radio className={styles.radioPadding} color="primary" />}
                                                label={<div className={styles.label}>Ne rien enregistrer</div>}
                                            />
                                            <FormControlLabel
                                                checked={this.state.modelSelected.isSavedPJ == "doc" ? true : false}
                                                value="doc"
                                                control={<Radio className={styles.radioPadding} color="primary" />}
                                                label={<div className={styles.label}>Enregistrer le document principal</div>}
                                            />
                                            <FormControlLabel
                                                checked={this.state.modelSelected.isSavedPJ == "pj" ? true : false}
                                                value="pj"
                                                control={<Radio className={styles.radioPadding} color="primary" />}
                                                label={<div className={styles.label}>Enregistrer les pièces jointes</div>}
                                            />
                                            <FormControlLabel
                                                checked={this.state.modelSelected.isSavedPJ == "all" ? true : false}
                                                value="all"
                                                control={<Radio className={styles.radioPadding} color="primary" />}
                                                label={<div className={styles.label}>Enregistrer le document principal + les pièces jointes</div>}
                                            />
                                        </div>
                                    </RadioGroup>
                                    :
                                    ""
                                }
                                <div>
                                    <FormControlLabel
                                        name={"gestionNotifs"}
                                        control={
                                            <Checkbox
                                                checked={this.state.isnotified}
                                                onChange={this.onChangeCb()}
                                                color='primary'
                                            />
                                        }
                                        label="Notifier lors d'un nouveau message sur un post-it."
                                    />
                                </div>
                                {this.state.modelSelected.isSavedPJ != "no" && this.state.typeModelSelected == "lyflow" ?
                                    <>
                                        {this.state.modelSelected.pathRapatriement != null && this.state.modelSelected.pathRapatriement != undefined ?
                                            <div>
                                                Les piéces jointes sont actuellement enregistrées à cet emplacement :
                                                <a title={this.makeUrlForPieceJointe(this.state.modelSelected.pathRapatriement)} target="_blank" href={this.makeUrlForPieceJointe(this.state.modelSelected.pathRapatriement)}>
                                                    {this.breadCrumbs(this.state.modelSelected.pathRapatriement, " ")}
                                                </a>
                                                <span style={{ paddingLeft: "20px", cursor: "pointer", display: "inline-flex", alignItems: "center" }} onClick={this.openCollapseFileExplorer}>
                                                    Modifier URL
                                                    <Icon>{this.state.showFileExplorer ? "expand_less" : "expand_more"}</Icon>
                                                </span>
                                            </div>
                                            :
                                            <div>
                                                <div>Choisir une liste de rapatriement </div>
                                            </div>
                                        }
                                        <Collapse in={this.state.showFileExplorer} timeout="auto" unmountOnExit>
                                            <FileExplorer isMultiSelect={false} showFolders={true} onChange={this.onChange("pathRapatriement")} />
                                        </Collapse>
                                    </>
                                    : ""}

                                <Button
                                    onClick={this.updateGestionPiecesJointes}
                                    variant="contained"
                                    className={styles.button}
                                    color="primary"
                                >
                                    VALIDER
                                </Button>
                            </div>
                        </>
                        : ""}
                </div>
            </div>)
    }
}