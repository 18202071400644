import * as React from "react";
import { observer } from "mobx-react";
import { IEmailProps } from "./interfaces/IEmailProps";
import { IEmailState } from "./interfaces/IEmailState";
import styles from "./styles/Email.scss";
import Store from "../store/store";
import { RouteComponentProps, withRouter } from "react-router-dom";
import axios from "axios";
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";
import Spinner from "../Spinner/Spinner";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

@observer
class Email extends React.Component<IEmailProps & RouteComponentProps, IEmailState> {
  constructor(props: IEmailProps & RouteComponentProps) {
    super(props);
    this.state = {
      listEmails: [],
      listEmailContents: [],
      loadingEmails: true,
      modalConfirmEditIsOpen:false,
      rowToEdit:undefined,
      editorName:"",
      editLink:""
    };
  }

  public componentDidMount() {
    this.getAllEmail();
  }
  public componentDidUpdate(prevProps, prevState) {

  }
  titleHover = (type: string) => (cell: string, row: any) => {
    return (
      <span title={
        type == "userFrom" ? row
          : type == "userTo" ? row
            : ""
      }>{cell}</span>
    )
  }
  public getAllEmail = () => {
    try {
      axios.get(Store.wsPath + '/1/email/GetAllEmail').then((response) => {
        this.setState({listEmails:response.data,loadingEmails:false});
      })
    } catch (error) {
      console.error("Error on 'getAllEmail' : ", error)
    }
  }
  redirectOrConfirmEdit = (row,editorName) =>{
    if((row.statut != "Publié" && row.editorId == Store.userConnected.id ) || row.statut == "Publié"){
      if (row.statut == "Publié" && (row.editorId == null || row.editorId == undefined)) {
        axios.get(Store.wsPath + '/1/email/UpdateStatus', { params: { id:row.id,editorId: Store.userConnected.id  } }).then((response) => {
          this.props.history.push("/emailContent?e=" + row.id);
        })
      }else{
        this.props.history.push("/emailContent?e=" + row.id);
      }
      
    }else{
      this.setState({rowToEdit:row.id, editorName:editorName, editLink:"/emailContent?e=" + row.id, modalConfirmEditIsOpen:true})
    }
  }
  confirmEdit =  () => {
    try {
      axios.get(Store.wsPath + '/1/email/ChangeEditor', { params: { id:this.state.rowToEdit, editorId: Store.userConnected.id } }).then((response) => {
        this.props.history.push(this.state.editLink);
      })
    } catch (error) {
      console.error("Error on 'confirmEdit' : ", error)
    }
  }
  onCloseModalSave = ()=>{
    this.setState({modalConfirmEditIsOpen:false,rowToEdit:undefined,editorName:"",editLink:""});
  }
  getActions = (cell, row) => {
    let editorName ="";
    if (row.editorId != null && row.editorId != undefined) {
      const editor = Store.allUsers.filter(u => u.id == row.editorId )[0];
      editorName = editor.firstname +" "+editor.lastname
    }
    return (
      <div className={styles.actionTable}>
        <div className={styles.actionJaune} title="Editer le mail" onClick={()=>this.redirectOrConfirmEdit(row,editorName)}><Icon className={styles.iconAction} >edit</Icon></div>
        {/* <div className={styles.actionJaune} title="Recevoir un exemple"><Icon className={styles.iconAction} onClick={() => { }}>mail</Icon></div> */}
      </div>
    )
  }
  descriptionFormat =(cell,row)=>{
    return (
      <span title={cell}>
        {cell}
      </span>
    );
  }
  getEditor = (cell, row) => {
    if (row.editorId != null && row.editorId != undefined) {
      const editor = Store.allUsers.filter(u => u.id == row.editorId )[0];
      return (
        <span title={editor.email}>
          {editor.firstname +" "+editor.lastname}
        </span>
      );
    } else {
      return (
        <span>
        </span>
      );
    }
    
  }
 
  getActionsCorpsMail = (cell, row) => {
    return (
      <div className={styles.actionTable}>
        <div className={styles.actionJaune} title="Créer ou modifier un brouillon"><Icon className={styles.iconAction} onClick={() => { }}>edit</Icon></div>
        <div className={styles.actionJaune} title="Valider le brouillon"><Icon className={styles.iconAction} onClick={() => { }}>edit</Icon></div>
        <div className={styles.actionRouge} title="Supprimer"><Icon className={styles.iconAction} onClick={() => { }}>delete</Icon></div>
      </div>
    )
  }
  render() {
    const optionsEmailList = {
      sortIndicator: true,
      defaultSortName: 'id',
      noDataText: 'Aucun mail à afficher',
      sizePerPage: 10
    };
    return (
      <>
        {/* <div className={styles.block}>
          <div className={styles.contentCheckbox} style={{ background: Store.themeParam.backgroundPrimary }}>
            <h5>Corps des mails</h5>
          </div>
          {/* <div className={styles.actionBar}>
                <Button onClick={this.onNewModel} className={styles.addButton} variant="contained" color="primary"><Icon>add</Icon>Créer un modèle</Button>
            </div> */}
          {/*<div className={styles.tableDeleg}>
            {this.state.loadingEmails ?
              <Spinner label="Chargement des corps de mail" labelPlacement="bottom" style={{ margin: "30px" }} />
              :
              <BootstrapTable data={this.state.listEmailContents} options={optionsEmailList}
                pagination
                trClassName={styles.trBootstrapTableModel}
                search
                searchPlaceholder="Rechercher..."
                bordered={false}
                striped
                tableHeaderClass={styles.tableThHeader}
                headerContainerClass={styles.tableHeader}
              >
                <TableHeaderColumn dataField='id' hidden isKey>id</TableHeaderColumn>
                <TableHeaderColumn dataField='name' tdStyle={{ verticalAlign: 'middle' }}>Nom</TableHeaderColumn>
                <TableHeaderColumn dataField='circuitDisplay' tdStyle={{ verticalAlign: 'middle' }}>Dernière modification</TableHeaderColumn>
                <TableHeaderColumn dataField='userFromDisplay' tdStyle={{ verticalAlign: 'middle' }} dataFormat={this.titleHover("userFrom")}
                  searchable={(Store.userConnected.typeUserLydoc === "ged" || Store.userConnected.typeUserLydoc === "admin")}
                >
                  Modifié Par
                        </TableHeaderColumn>
                <TableHeaderColumn width="140px" dataField="action" tdStyle={{ padding: '0px' }} dataFormat={this.getActionsCorpsMail}>Actions</TableHeaderColumn>
              </BootstrapTable>
            }
          </div>
        </div> */}
        <div className={styles.block}>
          <div className={styles.contentCheckbox} style={{ background: Store.themeParam.backgroundPrimary }}>
            <h5>Configuration des mails </h5>
          </div>
          {/* <div className={styles.actionBar}>
                        <Button onClick={this.onNewModel} className={styles.addButton} variant="contained" color="primary"><Icon>add</Icon>Créer un modèle</Button>
                    </div> */}
          <div className={styles.tableDeleg}>
            {this.state.loadingEmails === true ?
              <Spinner label="Chargement des mails" labelPlacement="bottom" style={{ margin: "30px" }} />
              :
              <BootstrapTable data={this.state.listEmails} options={optionsEmailList}
                pagination
                trClassName={styles.trBootstrapTableModel}
                search
                searchPlaceholder="Rechercher..."
                bordered={false}
                striped
                tableHeaderClass={styles.tableThHeader}
                headerContainerClass={styles.tableHeader}
              >
                <TableHeaderColumn dataField='id' hidden isKey>id</TableHeaderColumn>
                <TableHeaderColumn dataField='description'  dataFormat={this.descriptionFormat} tdStyle={{ verticalAlign: 'middle' }}>Description</TableHeaderColumn>
                <TableHeaderColumn dataField='outil' dataSort={true} tdStyle={{ verticalAlign: 'middle' }}>Outil</TableHeaderColumn>
                <TableHeaderColumn dataField='statut' tdStyle={{ verticalAlign: 'middle' }}>Statut</TableHeaderColumn>
                <TableHeaderColumn dataField='editorId' tdStyle={{ verticalAlign: 'middle' }} dataFormat={this.getEditor}>Edité par</TableHeaderColumn>
                <TableHeaderColumn width="140px" dataField="action" tdStyle={{ padding: '0px'}} dataFormat={this.getActions}>Actions</TableHeaderColumn>
              </BootstrapTable>
            }
          </div>
        </div>
        <Dialog
          maxWidth='xs'
          open={this.state.modalConfirmEditIsOpen}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <DialogTitle>
            <div className={styles.modalTitle}>Confirmation d'édition</div>
          </DialogTitle>
          <DialogContent>
            <div className={styles.modalContent}>
              <p>L'email que vous souhaitez modifier est déja en cours de modification par {this.state.editorName}.</p>
              <p>Voulez-vous annuler ses modifications et passer en édition ?</p>
              <div className={styles.modalButtons}>
                <Button
                  onClick={this.confirmEdit}
                  variant="contained"
                  color="primary"
                >
                  Confirmer l'édition
                </Button>
                <Button
                  onClick={this.onCloseModalSave}
                  variant="contained"
                  color="secondary"
                >
                  Annuler
                </Button>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </>
    );
  }
}

export default withRouter(Email);