import * as React from 'react'
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import 'simplebar/dist/simplebar.min.css';
const styles = require('./styles/parameterAutoStart_styles.scss');
import Store from '../store/store'
import axios from 'axios'
import { observer } from "mobx-react";
import FileExplorer from "../FileExplorer/FileExplorer";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { IModelWf } from '../Workflow/interfaces/IWorkflow';
import Spinner from '../Spinner/Spinner';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import TreeviewSelect from '../helper/TreeSelect/TreeSelect';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

interface IListModelTreeview {
  label: string;
  value: number;
  children?: IListModelTreeview[];
  checked?: boolean;
}

interface Props {
}

interface State {
  allLibraries: any[];
  error: boolean;
  selectedLibrarie: any;
  loading: boolean;
  loadingData: boolean;
  loadingButton: boolean;
  allModels: IModelWf[];
  openModal: boolean;
  allModelsTreeView: IListModelTreeview[];
}

@observer
export default class ParameterAutoStart extends React.Component<Props, State>{

  public constructor(props: Props) { // Dans le constructeur, on initialise toujours les states
    super(props)
    this.state = {
      allLibraries: [],
      error: false,
      loading: true,
      loadingData: true,
      loadingButton: false,
      allModels: [],
      allModelsTreeView: [],
      openModal: false,
      selectedLibrarie: null
    }
  }

  componentDidMount() {
    this.setState({ loading: true }, () => {
      this.getAllModels().then(allModels => {
        this.setState({ allModels: allModels.allModels, allModelsTreeView: allModels.allModelsTreeView, loading: false }, () => {
          this.getConfigAutoStart();
        });
      }).catch(error => {
        this.setState({ error: true });
        Store.snackBar = {
          open: true,
          message: "Une erreur est survenue lors de la récupération des modèles de circuit",
          error: true
        };
      });
    });
  }

  public getAllModels = () => {
    return new Promise<{ allModels: IModelWf[], allModelsTreeView: IListModelTreeview[] }>((resolve, reject) => {
      axios.get(Store.wsPath + "/1/workflows/getAllModelWf", {
        params: {
          filterModel: "lyflow"
        }
      }).then(responseModels => {
        const allModels: IModelWf[] = responseModels.data != undefined && responseModels.data != null && responseModels.data.length > 0 ? responseModels.data : [];
        const allFather: IModelWf[] = allModels.filter(model => model.idParent == null);
        const allModelsFormated: IListModelTreeview[] = allFather.map(father => {
          const children: IListModelTreeview[] = this.getChildrensModel(father, allModels);
          const itemList: IListModelTreeview = {
            label: father.name,
            value: father.id,
            children: children
          };
          return itemList;
        });

        resolve({ allModels: allModels, allModelsTreeView: allModelsFormated });
      }).catch(error => {
        console.error("Error 'getAllModels' : ", error);
        reject();
      });
    });
  }

  // Fonction qui cherche les enfants des modèles pour fabriquer l'arbre du Treeview
  public getChildrensModel = (modelParent: any, allModels: IModelWf[]): IListModelTreeview[] => {
    const findChildren: IModelWf[] = allModels.filter(mod => mod.idParent == modelParent.id);
    if (findChildren.length > 0) {
      const allChildrens: IListModelTreeview[] = findChildren.map(child => {
        const childrens: IListModelTreeview[] = this.getChildrensModel(child, allModels);
        return {
          label: child.name,
          value: child.id,
          children: childrens
        };
      });
      return allChildrens;
    }
    else {
      return [];
    }
  }

  public onchange = (result) => {
    this.setState({ allLibraries: result });
  }

  private formatLink = (cell, row) => {
    let link: any = "";
    switch (row.type) {
      case "library":
        const sitePath: string = row.sitePath;
        let sitePathFormated: string = row.sitePath
        if (sitePath.endsWith("/") == true) {
          sitePathFormated = sitePath.slice(0, sitePath.length - 1);
        }
        link = <a href={sitePathFormated + "/" + row.libraryName} target="_blank">{row.label}</a>
        break;
      default:
        break;
    }
    return link;
  }

  private formatCircuit = (cell, row) => {
    let circuitName: string = "Aucun circuit sélectionné";
    if (cell != undefined && cell != null) {
      const findModel = this.state.allModels.find(mod => mod.id == cell);
      if (findModel != undefined) {
        circuitName = findModel.name;
      }
      else {
        circuitName = "Circuit introuvable";
      }
    }
    return circuitName;
  }

  private toolbox = (cell, row) => {
    return (
      <div style={{ textAlign: "center" }}>
        <Icon onClick={() => this.openCloseEditModal(true, row)} style={{ marginRight: "5px", cursor: "pointer" }}>mode_edit</Icon>
        <Icon onClick={this.deleteLibrary(row)} style={{ cursor: "pointer" }}>delete</Icon>
      </div>
    )
  }

  private deleteLibrary = (row: any) => event => {
    const allLib: any[] = this.state.allLibraries.filter(lib => lib.key != row.key);
    this.setState({ allLibraries: allLib });
  }

  // Sauvegarde la liste des bibliothèques sélectionnées en base
  private saveConfigAutoStart = () => {
    this.setState({ loadingButton: true }, () => {
      axios.post(Store.wsPath + "/1/autoStart/createOrUpdateConfig", {
        title: "autoStart",
        value: this.state.allLibraries
      }).then(responseConfig => {
        if (responseConfig.status == 200 && responseConfig.data.value != undefined && responseConfig.data.value != null) {
          this.setState({ allLibraries: responseConfig.data.value, loadingButton: false });
          Store.snackBar.message = "La configuration a été sauvegardée.";
          Store.snackBar.error = false;
          Store.snackBar.open = true;
        }
      }).catch(error => {
        console.error("Error 'saveConfigAutoStart' : ", error);
        this.setState({ loadingButton: false });
        Store.snackBar.message = "Erreur lors de la sauvegarde de la configuration";
        Store.snackBar.error = true;
        Store.snackBar.open = true;
      });
    });
  }

  // Get all selected bibliothèques
  private getConfigAutoStart = () => {
    this.setState({ loadingData: true }, () => {
      axios.get(Store.wsPath + "/1/autoStart/getConfigByTitle", {
        params: {
          title: "autoStart"
        }
      }).then(responseConfig => {
        if (responseConfig.status == 200 && responseConfig.data.value != undefined && responseConfig.data.value != null && responseConfig.data.value.length > 0) {
          this.setState({ allLibraries: responseConfig.data.value, loadingData: false });
        }
        else {
          this.setState({ loadingData: false });
          Store.snackBar.message = "Pas de configuration pour le moment";
          Store.snackBar.error = true;
          Store.snackBar.open = true;
        }
      }).catch(error => {
        this.setState({ loadingData: false });
        console.error("Error 'getConfigAutoStart' : ", error);
        Store.snackBar.message = "Erreur lors de la récupération de la configuration";
        Store.snackBar.error = true;
        Store.snackBar.open = true;
      });
    });
  }


  private formatIfExist = (cell, row) => {
    if (cell == false) {
      return (
        <div style={{ textAlign: "center" }}>
          <Icon title="Vérifier si la liste ou le site sélectionné existe toujours" style={{ cursor: "pointer", color: "#ff9800" }}>warning</Icon>
        </div>
      );
    }
    else {
      return (
        <div style={{ textAlign: "center" }}>
          <Icon title="La liste ou le site sélectionné existe" style={{ cursor: "pointer", color: "green" }}>check</Icon>
        </div>
      );
    }
  }

  private openCloseEditModal = (open: boolean, row?) => {
    if (open == true) {
      const treeviewData = [...this.state.allModelsTreeView];
      if (row.modelId != undefined && row.modelId != null) {
        this.foundModelInTreeview(treeviewData, row.modelId);
      }
      this.setState({ selectedLibrarie: { ...row }, openModal: true, allModelsTreeView: treeviewData });
    }
    else {
      const treeviewData = [...this.state.allModelsTreeView];
      this.foundModelInTreeview(treeviewData);
      this.setState({ selectedLibrarie: null, openModal: false, allModelsTreeView: treeviewData });
    }
  }

  private foundModelInTreeview = (childrenModels: IListModelTreeview[], modelId?: number) => {
    childrenModels.forEach(model => {
      if (modelId == undefined || modelId == null) {
        model.checked = false;
      }
      else {
        if (model.value == modelId) {
          model.checked = true;
        }
      }

      if (model.children != undefined && model.children != null && model.children.length > 0) {
        this.foundModelInTreeview(model.children, modelId);
      }
    })
  }

  // Fonction onChange appelée sur la selection du modéle
  public onChangeSelect = (currentNode, selectedNodes) => {
    if (selectedNodes.length > 0) {
      const myLibSelected = { ...this.state.selectedLibrarie };
      myLibSelected["modelId"] = selectedNodes[0].value;
      this.setState({ selectedLibrarie: myLibSelected });
    }
    else {
      const myLibSelected = { ...this.state.selectedLibrarie };
      myLibSelected["modelId"] = null;
      this.setState({ selectedLibrarie: myLibSelected });
    }
  }

  public validateModel = () => event => {
    const selectedLib = { ...this.state.selectedLibrarie };
    const allLib = [...this.state.allLibraries];
    const findLibIndex: number = allLib.findIndex(lib => lib.libraryId == selectedLib.libraryId);
    if (findLibIndex != -1) {
      allLib[findLibIndex] = selectedLib;
    }
    this.setState({ allLibraries: allLib }, () => { this.openCloseEditModal(false) });
  }


  public render() {
    return (
      <div className={styles.firstContent}>
        <div className={styles.paramTitle} style={{ background: Store.themeParam.backgroundPrimary }}>
          <h5>Paramètres démarrage auto :</h5>
        </div>
        <div className={styles.paramAutoStartContent}>
          {
            this.state.error == true ?
              <p style={{padding:"20px", textAlign: "center"}}>Une erreur est survenue lors du chargement de la page, veuillez recharcher la page...</p>
              :
              this.state.loading == true ?
                <Spinner label="Chargement en cours..." />
                :
                <React.Fragment>
                  <FileExplorer isMultiSelect={true} showFolders={false} onChange={this.onchange} contentTypeFilterName={"Sherpa_Lyflow"} value={this.state.allLibraries} />
                  <h5 className={styles.title}>Liste des bibliothèques sélectionnées :</h5>
                  <BootstrapTable
                    className={styles.resultTab}
                    multiColumnSearch
                    data={this.state.allLibraries}
                    searchPlaceholder="Rechercher"
                    search={true}
                    pagination
                    version="4"
                    ref="table"
                    options={{
                      noDataText: this.state.loadingData == true ? "Chargement en cours..." : "Pas de listes selectionnées"
                    }}
                  >
                    <TableHeaderColumn isKey={true} hidden dataField="key">
                      Key
                        </TableHeaderColumn>
                    <TableHeaderColumn tdStyle={{ padding: '0', verticalAlign: 'inherit' }} width='40px' headerAlign='center' dataAlign='center' dataSort={true} dataField="exist" dataFormat={this.formatIfExist}></TableHeaderColumn>
                    <TableHeaderColumn headerAlign='center' dataAlign='center' dataSort={true} dataField="libraryName">
                      Bibliothèque
                        </TableHeaderColumn>
                    <TableHeaderColumn headerAlign='center' dataAlign='center' dataSort={true} dataField="siteName">
                      Site
                        </TableHeaderColumn>
                    <TableHeaderColumn headerAlign='center' dataAlign='center' dataSort={true} dataField="label" dataFormat={this.formatLink}>
                      Lien
                        </TableHeaderColumn>
                    <TableHeaderColumn headerAlign='center' dataAlign='center' dataSort={true} dataField="modelId" dataFormat={this.formatCircuit}>
                      Circuit associé
                        </TableHeaderColumn>
                    <TableHeaderColumn headerAlign='center' width='10%' dataField='id' tdStyle={{ padding: '0', verticalAlign: 'inherit' }} dataFormat={this.toolbox}>
                      Actions
                        </TableHeaderColumn>
                  </BootstrapTable>

                  <Button
                    type="button"
                    // className={styles.btnUser + " primaryColor"}
                    variant="contained"
                    color="primary"
                    onClick={this.saveConfigAutoStart}
                    disabled={this.state.loadingButton}
                  >
                    {this.state.loadingButton == true ?
                      "Chargement..."
                      :
                      "Sauvegarder"
                    }
                  </Button>

                  <Dialog onClose={(event) => this.openCloseEditModal(false)} aria-labelledby="Modal-edit-circuit" open={this.state.openModal} className={styles.editModelDialog}>
                    <DialogTitle>Modifier le circuit associé à cette bibliothèque</DialogTitle>
                    <DialogContent>
                      <TreeviewSelect
                        texts={{ placeholder: "Sélectionnez un modéle de circuit", inlineSearchPlaceholder: "Rechercher", noMatches: "Aucun résultat" }}
                        mode={"radioSelect"}
                        data={this.state.allModelsTreeView}
                        onChange={this.onChangeSelect}
                        inlineSearchInput={true}
                        className={styles.treeView}
                      />
                      <DialogActions>
                        <Button
                          type="button"
                          variant="contained"
                          color="primary"
                          onClick={this.validateModel()}
                        >
                          Valider
                      </Button>
                        <Button
                          type="button"
                          variant="contained"
                          color="default"
                          onClick={() => this.openCloseEditModal(false)}
                        >
                          Fermer
                      </Button>
                      </DialogActions>
                    </DialogContent>
                  </Dialog>
                </React.Fragment>
          }
        </div>
      </div>
    )
  }
}