import * as React from 'react';
import { MenuItem } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import SelectReact, { createFilter } from 'react-select';
const styles = require('../../styles/styles.module.scss');
import Store from '../store/store';
import {getAllUsers} from '../store/store';
import axios from 'axios';
import { observer } from "mobx-react";

interface Props {
}

interface IState {
    errorLogin: boolean;
    suggestionsTypeUSer:{value:string,label:string}[];
    selectedTypeUser :{value:string,label:string};
    formRightsSuggestion:{value:string,label:string}[];
    selectedFormRights :{value:string,label:string};
}

@observer
export default class User extends React.Component<Props, IState>{
    private formRightsSuggestion = [
        { value: "Lecture", label: "Lecture" },
        { value: "Modification", label: "Gestionnaire" },
        { value: "Admin", label: "Administrateur" }
    ];
    public constructor(props: Props) { // Dans le constructeur, on initialise toujours les states
        super(props);
        this.state = {
            errorLogin: false,
            suggestionsTypeUSer:[{ value: "all", label: "Utilisateur" },
            { value: "ged", label: "GED" },
            { value: "admin", label: "Admin" }],
            selectedTypeUser: [{ value: "all", label: "Utilisateur" },
            { value: "ged", label: "GED" },
            { value: "admin", label: "Admin" }].find( s => s.value == Store.myUser.typeUserLydoc),
            formRightsSuggestion: this.formRightsSuggestion,
            selectedFormRights: this.formRightsSuggestion.find( s => s.value == Store.myUser.formRights)
        }
        if(Store.myUser.accessConsult == null){
            Store.myUser.accessConsult = false
        } 
        if(Store.myUser.accessRef == null){
            Store.myUser.accessRef = false
        }
    }

    componentDidMount() {
    }

    public onEditUser = (event) => {
      event.preventDefault();
      axios.post(Store.wsPath + '/1/users/updateUser', {
        id: Store.myUser.id,
        lastname: Store.myUser.lastname,
        firstname: Store.myUser.firstname,
        login: Store.myUser.login,
        email: Store.myUser.email,
        civility: Store.myUser.civility,
        typeUserLydoc : Store.myUser.typeUserLydoc,
        formRights:Store.myUser.formRights
      }).then(response => {
        getAllUsers()
        if(Store.myUser.id == Store.userConnected.id){
          Store.userConnected.lastname=  Store.myUser.lastname,
          Store.userConnected.firstname= Store.myUser.firstname,
          Store.userConnected.email= Store.myUser.email,
          Store.userConnected.typeUserLydoc = Store.myUser.typeUserLydoc,
          Store.userConnected.formRights = Store.myUser.formRights
        }
        Store.contextualmenu.open = "hidden";
        Store.contextualmenu.content = ""
        Store.myUser = Store.DefaultValueMyUser;
        // SnackBar
        Store.snackBar.message = "Utilisateur modifié avec succès"
        Store.snackBar.open = true;
      });
    }

    public onValidateUser = (event) => {
        event.preventDefault();

        const findLoginUser = Store.allUsers.find(user => user.login.toUpperCase() == Store.myUser.login && user.id != Store.myUser.id);
        if(Store.myUser.accessConsult && Store.myUser.accessRef){
            Store.myUser.typeUserLydoc = "ged"
        }else if(Store.myUser.accessConsult == true && Store.myUser.accessRef == false){
            Store.myUser.typeUserLydoc = "lecteur"
        }else if(Store.myUser.accessConsult == false && Store.myUser.accessRef == true){
            Store.myUser.typeUserLydoc = "contributeur"
        }else if(Store.myUser.accessConsult == false && Store.myUser.accessRef == false){
            Store.myUser.typeUserLydoc = "rien"
        }
        if (findLoginUser == undefined) {
            axios.post(Store.wsPath + '/1/users/createUser', {
                lastname: Store.myUser.lastname,
                firstname: Store.myUser.firstname,
                login: Store.myUser.login,
                email: Store.myUser.email,
                // idServ: // TODO JJ ARBRE
                // idProf: // TODO JJ ARBRE
                civility: Store.myUser.civility,
                accessConsult: Store.myUser.accessConsult,
                accessRef: Store.myUser.accessRef,
                typeUserLydoc : Store.myUser.typeUserLydoc
            }).then(response => {
                getAllUsers();
                Store.contextualmenu.open = "hidden";
                Store.contextualmenu.content = ""
                Store.myUser = Store.DefaultValueMyUser;
                // SnackBar
                Store.snackBar.message = "Utilisateur ajouté avec succès"
                Store.snackBar.open = true;
            })
        }
        else {
            this.setState({ errorLogin: true });
        }
    }

    onChangeFirstNameUser = (e) => {
        Store.myUser.firstname = e.target.value
    }

    onChangeUser = (name) => event => {
        if (name == "firstname") {
            Store.myUser.firstname = event.target.value;
        } else if (name == "lastname") {
            Store.myUser.lastname = event.target.value;
        } else if (name == "email") {
            Store.myUser.email = event.target.value;
        } else if (name == "login") {
            Store.myUser.login = event.target.value;
        } else if (name == "civility") {
            Store.myUser.civility = event.target.value;
        } else if (name == "typeUserLydoc") {
            Store.myUser.typeUserLydoc = event.target.value;
        }
    }
    public onChange = (option, type: string) => {
        if(type == "typeUserLydoc") {
            Store.myUser[type] = option.value;
            this.setState({selectedTypeUser:option});
        }
        else if(type == "formRights") {
            Store.myUser[type] = option.value;
            this.setState({selectedFormRights:option});
        }
    }
    public render() {
        // const suggestionCivilité= [ 
        //   { value: "Mr.", label: "Mr." },
        //   { value: "Mme", label: "Mme" },
        //   { value: "Dr", label: "Dr" },
        //   { value: "Pr", label: "Pr" }
        // ];
        return (
            <div>
                <form autoComplete="false" onSubmit={Store.contextualmenu.content == "editUser" ? this.onEditUser : this.onValidateUser}>
                    <h5 className={styles.titleContextual}>{Store.contextualmenu.content == "editUser" ? "Modifier un utilisateur" : "Ajouter un utilisateur"}</h5>
                    {/* <SelectReact
                        options={suggestionCivilité}
                        onChange={option => this.onChange(option ? option : null, 'civility')}
                        placeholder="Civilité"
                        filterOption={createFilter({ ignoreAccents: false })}
                        value={{value:Store.myUser.civility,label:Store.myUser.civility}}
                        className={styles.field +" "+ styles.fieldSelectEditUser}
                        styles={{ control: styles => ({ ...styles, backgroundColor: 'transparent', padding: '2px 0', color: 'primary' }) }}
                        isClearable={false}
                        isDisabled={Store.userConnected.typeUserLydoc != "admin" && Store.userConnected.typeUserLydoc != "ged" ? true : false}
                        isSearchable ={false}
                        
                    /> */}
                    
                    <TextField
                        InputLabelProps={Store.contextualmenu.content == "editUser" ? { shrink: true } : {}}
                        variant='outlined'
                        className={styles.field}
                        required
                        type="text"
                        value={Store.myUser.lastname != undefined ? Store.myUser.lastname : ""}
                        onChange={this.onChangeUser('lastname')}
                        label="Nom de l'utilisateur"
                        disabled={Store.userConnected.typeUserLydoc == "admin" ? false : true}
                    // inputProps={{classes:styles.inputField}}
                    />
                    <TextField
                        name="firstname"
                        value={Store.myUser.firstname != undefined ? Store.myUser.firstname : ""}
                        InputLabelProps={Store.contextualmenu.content == "editUser" ? { shrink: true } : {}}
                        label="Prénom de l'utilisateur"
                        onChange={this.onChangeUser("firstname")}
                        margin="normal"
                        variant='outlined'
                        required
                        type="text"
                        className={styles.field}
                        disabled={Store.userConnected.typeUserLydoc == "admin"  ? false : true}
                    />
                    <TextField
                        name="email"
                        value={Store.myUser.email != undefined ? Store.myUser.email : ""}
                        InputLabelProps={Store.contextualmenu.content == "editUser" ? { shrink: true } : {}}
                        label="Email de l'utilisateur"
                        onChange={this.onChangeUser("email")}
                        margin="normal"
                        variant='outlined'
                        required
                        type="email"
                        className={styles.field}
                        disabled={Store.userConnected.typeUserLydoc == "admin" ? false : true}
                    />
                    <TextField
                        name="login"
                        value={Store.myUser.login != undefined ? Store.myUser.login : ""}
                        InputLabelProps={Store.contextualmenu.content == "editUser" ? { shrink: true } : {}}
                        label="Login de l'utilisateur"
                        onChange={this.onChangeUser("login")}
                        margin="normal"
                        variant='outlined'
                        required
                        type="text"
                        className={styles.field}
                        error={this.state.errorLogin}
                        helperText={this.state.errorLogin ? "Login déjà existant" : ""}
                        disabled={true}
                    />
                    
                    <p>Droits :</p>
                    <SelectReact
                        options={this.state.suggestionsTypeUSer}
                        onChange={option => this.onChange(option ? option : null, 'typeUserLydoc')}
                        placeholder="Niveau"
                        filterOption={createFilter({ ignoreAccents: false })}
                        value={this.state.selectedTypeUser}
                        className={styles.field}
                        styles={{ control: styles => ({ ...styles, backgroundColor: 'transparent', padding: '2px 0', color: 'primary' }), menu: base => ({ ...base, zIndex: 99999 }) }}
                        isClearable={false}
                        isDisabled={Store.userConnected.typeUserLydoc != "admin" ? true : false}
                        isSearchable ={false}
                        
                    />
                    {Store.paramYes.products != undefined && Store.paramYes.products != null &&  Store.paramYes.products.form == true?
                      <React.Fragment>
                        <p>Droits Formulaire :</p>
                        <SelectReact
                            options={this.state.formRightsSuggestion}
                            onChange={option => this.onChange(option ? option : null, 'formRights')}
                            placeholder="Droit formulaire"
                            filterOption={createFilter({ ignoreAccents: false })}
                            value={this.state.selectedFormRights}
                            className={styles.field}
                            styles={{ control: styles => ({ ...styles, backgroundColor: 'transparent', padding: '2px 0', color: 'primary' }), menu: base => ({ ...base, zIndex: 99999 }) }}
                            isClearable={false}
                            isDisabled={Store.userConnected.typeUserLydoc != "admin" ? true : false}
                            isSearchable={false}
                        />
                      </React.Fragment>
                      :<React.Fragment/>
                    }
                    
                    { Store.userConnected.typeUserLydoc == "admin"  ?
                      <Button
                          type="submit"
                          // className={styles.btnUser + " primaryColor"}
                          variant="contained"
                          color="primary"
                        >
                          Valider
                        </Button>
                      :<React.Fragment></React.Fragment>
                  
                    }
                    <Button
                        type="button"
                        variant="contained"
                        className={styles.btnCancel}
                        color="default"
                        onClick={() => { Store.contextualmenu.open = "hidden"; Store.contextualmenu.content = ""; Store.myUser = Store.DefaultMyUser }}
                    >
                        { Store.userConnected.typeUserLydoc == "admin" ? "Annuler":"Fermer"}
                    </Button>
                </form>
            </div>
        )
    }
}