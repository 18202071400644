import * as React from "react";
import DropdownTreeSelect from 'react-dropdown-tree-select';
import 'react-dropdown-tree-select/dist/styles.css';
const styles = require('./styles/TreeSelect.module.scss');

export interface TreeSelectProps {
  texts: any;
  mode: any;
  data: any;
  onChange: any;
  className?: string;
  inlineSearchInput: boolean;
}

export interface TreeSelectState {
  data: any;
}

export default class TreeSelect extends React.Component<TreeSelectProps, TreeSelectState> {
  constructor(props: TreeSelectProps) {
    super(props);
    this.state = {
      data: props.data
    };
  }

  public componentWillReceiveProps(nextProps: TreeSelectProps) {
    if (JSON.stringify(nextProps.data) != JSON.stringify(this.state.data)) {
      this.setState({ data: nextProps.data });
    }
  }

  public shouldComponentUpdate(nextProps: TreeSelectProps) {
    if (JSON.stringify(nextProps.data) != JSON.stringify(this.state.data)) {
      return true;
    }
    else {
      return false;
    }
  }

  public render() {
    const { data, className, ...rest } = this.props;
    return (
      <DropdownTreeSelect
        data={this.state.data}
        {...rest}
        className={className != undefined && className != null ? styles.treeSelectContainer + " " + className : styles.treeSelectContainer}
      />
    );
  }
}