import { observable, computed } from "mobx";
import { IUser, IFormatUsers } from '../parametersUsers/interfaces/IUser';
import { IDoc } from '../ListeDocuments/interfaces/IDoc';
import { IHistoric } from '../Menus/interfaces/IHistoric';
import { ISnackBar } from "../SnackBar/interfaces/ISnackBar";
import { IContextualMenu } from "../Menus/interfaces/IContextualMenu";
import { ITheme } from "../../interfaces/ITheme";
import { IDBWorkflow, StepYes, IModelWf, IStepsModel, IDelegation, IWSDisplayFilterTaskSlider, IDBWfTask, ITypeForm } from "../Workflow/interfaces/IWorkflow";
import { IIconStatus } from "../Menus/interfaces/IIconStatus";
import { IDelegation as IDelegationND, IModelDelegation } from "../delegation/interfaces/delegation";
import { IRole } from "../parametersRoles/interfaces/IRole";
import { ISharepointMenu } from "../Menus/interfaces/ISharepointMenu";
import themeConfig from "../../styles/themeConfig";

import axios from "axios";
import { IParam } from "../parameters/interfaces/IParam";
const Window: any = window;

function compareUser(a, b) {
    if (a.lastname < b.lastname) {
        return -1;
    }
    if (a.lastname > b.lastname) {
        return 1;
    }
    return 0;
}


export interface IReload {
    form: boolean;
    banette: boolean
}

class Store {
    // Local
    // @observable wsPath = "https://lydocdemo.azurewebsites.net"
    // @observable authMode ="Local";

    // @observable wsPath = "https://51.75.203.86:3443";
    // @observable authMode ="AAD";


    // LyFlow

    // @observable wsPath = "https://lyflow.spyes.fr:3443";
    // @observable authMode ="AAD";

    // nouveau server lyflow
    //  @observable wsPath = "https://yes.sherpateam.fr:10143";
    //  @observable authMode ="AAD";


    // test local AS

    @observable wsPath: string = Window.URLBACK;
    @observable authMode: string = Window.MODEAUTH; // Local pour le mode pâs sherpoint , AAD pour sharepoint online et AD pour sharepoint onprem. !!! NE PAS OUBLIER DE FAIRE UN SEED A CHAQUE FOIS QUE JE CHANGE DE MODE
    @observable modeSharepoint: boolean = Window.MODESHAREPOINT; // A changer pour changer de mode
    @observable funMode: boolean = Window.FUNMODE;
    @observable searchForm: boolean = Window.SEARCHFORM;
    @observable paramYes:any={};

    // WS Azure
    // @observable wsPath = "https://lydocback.azurewebsites.net"

    // WS IIS
    // @observable wsPath = "http://192.168.1.82:9000"
    // @observable authMode ="Local";


    @observable OwaPath = "http://dem-owa/op/embed.aspx?src="
    @observable historicAction: IHistoric = {
        openModalHistoric: false
    }

    @observable menuSharepoint: ISharepointMenu = {
        menuSharepointOpen: false
    }

    @observable iconStatus: IIconStatus = {
        document: true,
        settings: false,
        sharepoint: false
    }
    @observable isLoginHidden: boolean = true;
    @observable loadingSpinner: boolean = false;
    @observable licorne: boolean = false
    @observable isUploadDocument: boolean = false;
    @observable userConnected: IUser = {
        id: undefined,
        civility: undefined,
        lastname: undefined,
        firstname: undefined,
        matricule: undefined,
        email: undefined,
        login: undefined,
        password: undefined,
        service: undefined, // nom du service
        profession: undefined, // nom de la profession
        fonction: undefined, // nom de la fonction
        id_fonction: undefined, // john
        id_profession: undefined, // john
        id_service: undefined, // john
        typeUser: undefined,
        typeUserLydoc: undefined,
        accessParam: false,
        accessSignalement: false,
        isGroup : undefined,
        usersOfGroup : undefined,
        formRights: undefined
    }

    @observable listDocsDisplaying: IDoc[] // Liste de documents à afficher
    @observable divDisplayed: string = "" // Mode Liste ou Formulaire
    @observable isNeedActionToDocument: boolean = false
    @observable consulting: boolean = false
    @observable messageMotifReturn: string = ""
    @observable messageMotifRefus: string = "";
    @observable snackBar: ISnackBar = {
        open: false,
        message: "",
        error: false
    }
    @observable contextualmenu: IContextualMenu = {
        content: "",
        open: "hidden" // 3 Etats :  hidden , open et extended
    }

    DefaultContextualMenu: IContextualMenu = {
        content: "",
        open: "hidden"
    }

    @computed get defaultValueContextualMenu() {
        return this.DefaultContextualMenu;
    }

    @observable myUser: IUser = {
        id: undefined,
        civility: undefined,
        lastname: undefined,
        firstname: undefined,
        matricule: undefined,
        email: undefined,
        login: undefined,
        password: undefined,
        id_service: undefined, // john
        id_fonction: undefined, // john
        id_profession: undefined, // john
        typeUser: undefined,
        typeUserLydoc: undefined, // john
        accessConsult: false, // john
        accessRef: false, // john
        accessParam: undefined,
        accessSignalement: undefined,
        isGroup : undefined,
        usersOfGroup : undefined,
        formRights: undefined
        // id_fonctionResponsable : {ActionReferent: [], InfoResponsable: []}, // thomas
        // id_professionResponsable : {ActionReferent: [], InfoResponsable: []}, // Thomas
        // id_serviceResponsable : {ActionReferent: [], InfoResponsable: []}, // thomas
        // id_riskResponsable : {ActionReferent: [], InfoResponsable: []} // thomas */

    }
    DefaultMyUser: IUser = {
        id: undefined,
        civility: undefined,
        lastname: undefined,
        firstname: undefined,
        matricule: undefined,
        email: undefined,
        login: undefined,
        password: undefined,
        id_service: undefined, // john
        id_fonction: undefined, // john
        id_profession: undefined, // john
        typeUser: undefined,
        typeUserLydoc: undefined, // john
        accessConsult: false, // john
        accessRef: false, // john
        accessParam: undefined,
        accessSignalement: undefined,
        isGroup : undefined,
        usersOfGroup : undefined,
        formRights: undefined
        // id_fonctionResponsable : {ActionReferent: [], InfoResponsable: []}, // thomas
        // id_professionResponsable : {ActionReferent: [], InfoResponsable: []}, // Thomas
        // id_serviceResponsable : {ActionReferent: [], InfoResponsable: []}, // thomas
        // id_riskResponsable : {ActionReferent: [], InfoResponsable: []} // thomas */

    }

    @computed get DefaultValueMyUser() {
        return this.DefaultMyUser;
    }

    @observable allUsers: IUser[] = [];

    @observable allGroup: IUser[] = [];


    @observable selectedDoc: IDoc = {
        id: undefined,
        title: undefined
    }

    // @observable sharepointDocEditData: any = {};
    // @observable sharepointListFields: any[] = [];

    selectedDocDefault: IDoc = { // Pour remettre les valeurs de selectedDoc par défaut
        id: undefined,
        title: undefined,
        matriculeDoc: undefined,
        fileName: undefined,
        fileData: undefined,
        titleRgx: undefined,
        reference: undefined,
        object: undefined,
        objectRgx: undefined,
        nature: undefined,
        natureIdx: undefined,
        groupIdx: undefined,
        referencialIdx: undefined,
    }

    @computed get selectedDocDefaultValue() {
        return this.selectedDocDefault;
    }
    @observable newDocumentDefValue: IDoc = {
        reference: "",
        title: 'document_',
        fileName: "",
        object: "",
        nature: null,
        numVersion: "1.0",
        workflow: null,
        state: "En préparation",
        groupIdx: null
    }


    // @observable myNatureSelected : NatureYes = {
    //     id : 1,
    //     name : undefined
    // }

    @observable publishOrNot: number
    @observable idNature: number = 0
    @observable idFolder: number = 0

    @observable workflow: IDBWorkflow = {
        assistantWorkflow: false,
        assistantContent: "createProject",
        asssitantTitle: "Création du circuit documentaire",
        zoneComment: "",
        displayingComboStep: false,
        isPossibleToValidateProject: false,
        isExisted: false
    }

    // John --------------------------
    @observable editWorkflow: IDBWorkflow = {
        id: 0,
        name: undefined,
        plannedDateBegin: undefined,
        plannedDateEnd: undefined
    }

    @computed get DefaultValueEditWorkFlow() {
        return this.DefaultValueEditWorkFlow;
    }

    @observable reloadWorkFlow: boolean = false;

    @observable reload: IReload = {
        form: false,
        banette: false
    };

    @observable addeEditStep = {
        id: 0,
        idProject: 0,
        name: undefined,
        state: undefined,
        plannedDateBegin: undefined,
        plannedDateEnd: undefined,
        rankStep: 0,
        addAfterIndex: undefined,
        idResponsible: [],
        tasksList: [],
        clearForm: false
    }

    DefaultaddeEditStep = {
        id: 0,
        idProject: 0,
        name: undefined,
        state: undefined,
        plannedDateBegin: undefined,
        plannedDateEnd: undefined,
        rankStep: 0,
        addAfterIndex: undefined,
        idResponsible: [],
        tasksList: [],
        clearForm: false
    }

    @computed get DefaultValueAddeEditStep() {
        return this.DefaultaddeEditStep;
    }

    // -----------------------------------------------------

    DefaultWorkflow: IDBWorkflow = {
        assistantWorkflow: false,
        assistantContent: "createProject",
        asssitantTitle: "Création du circuit documentaire",
        zoneComment: "",
        id: undefined,
        name: undefined,
        plannedDateBegin: undefined,
        plannedDateEnd: undefined,
        WfSteps: [],
        displayingComboStep: false,
        isPossibleToValidateProject: false,
        isExisted: false,
        WfPostIts: [],
        Document: undefined
    }

    @computed get DefaultValueWorkFlow() {
        return this.DefaultWorkflow;
    }

    @observable stateDocumentSelected: string

    // Format User Autocompletion

    @observable formatUsers: IFormatUsers[] = []
    @observable valueFormatUsers: IFormatUsers[] = []
    @observable idDOcSelected: number[] = []
    @observable isFolderDisplaying: boolean = false
    @observable defaultValueExpansionWf: boolean = true
    @observable defaultValueExpansionEchanges: boolean = true
    @observable defaultValueExpansionDocAsso: boolean = true



    @observable defaultValueExpansionGroup: boolean = false
    @observable defaultValueExpansionFormulaire: boolean = false
    @observable defaultValueExpansionFiche: boolean = false;

    @observable modeDocument: number = undefined
    @observable desactiveSelect: any[] = []
    @observable param: IParam = {
        isAuthorizedPublish: false,
        isSavedPJ: false
    }
    @observable isNewDocument: boolean = false

    @observable themeParam: ITheme = themeConfig;

    @observable countDocumentsToPublish: number = 0 // retourne le nombre de document à publier pour le GED
    @observable isDisableditem: boolean = false
    @observable countTaskUser: number = 0
    // @observable arrayTask : ITasksYes[] = []
    @observable arrayTask: any[] = []
    @observable allProject: IDBWorkflow[] = []
    @observable allDocuments: IDoc[] = []
    @observable isList: boolean = true;
    @observable suggestionsUser: { value: number, label: string, mail: string, isGroup?: boolean }[] = [{ value: 0, label: "Chargement en cours ..." , mail: "Chargement en cours ...", isGroup: false }];
    @observable allModelWf: IModelWf[] = []
    @observable modelWf: IModelWf = {
        id: undefined,
        guid : undefined,
        name: undefined,
        isSavedPJ: "no",
        icon: { "value": 1, label: "account_circle" },
        pathRapatriement: undefined,
        isAModelDorientation: false,
        radicalChrono: "SHERPA",
        idParent: undefined,
        isNotified: true,
        isGRC: false,
        isForm:false,
        formType:undefined
    }

    DefaultmodelWf: IModelWf = {
        id: undefined,
        guid : undefined,
        name: undefined,
        isSavedPJ: "no",
        icon: { "value": 1, label: "account_circle" },
        pathRapatriement: undefined,
        isAModelDorientation: false,
        radicalChrono: "SHERPA",
        idParent: undefined,
        isNotified: true,
        isGRC: false,
        isForm:false,
        formType:undefined
    }
    @computed get defaultValueModelWf() {
        return this.DefaultmodelWf;
    }
    @observable myStepTaskSelectionned: IDBWfTask = undefined
    @observable myStepModel: IModelWf = undefined
    @observable stepModel: IStepsModel = undefined

    @observable allRoles: IRole[] = [];
    @observable addEditRole: IRole = {
        id: undefined,
        name: undefined,
        idxUser: undefined
    }
    DefaulRole: IRole = {
        id: undefined,
        name: undefined,
        idxUser: undefined
    }

    @computed get DefaultValueRole() {
        return this.DefaulRole;
    }

    @observable dateProjectStart: any = undefined
    @observable denied: boolean = false
    @observable boostrapData: any[] = [];

    // Store qui permet l'ajout de message en dehors du composant Post-it
    @observable addMessagePostIt: { message: string, add: boolean } = { message: "", add: false };
    @observable idStepsTab: number[] = [];
    @observable clickDisplaySliderTask: boolean = false;
    // @observable historyIdWorkflow: number = undefined;
    @observable openMenuForOne: boolean = false;
    @observable taskSliderTasks: IWSDisplayFilterTaskSlider[] = []
    @observable returnTask: boolean = false
    @observable reloadListModel: string = "no"; // Peut prendre plusieurs valeur, sois "all" pour recharger toute la liste des modéle et le modéle, soit "model" qui recharge juste les infos du modèle
    @observable idsStepOrientation: number[] = []
    @observable rankStepO: number = 0
    @observable orientationEnCours: boolean = false
    @observable allModelsInThisStep: any[] = [] // Get tous les modèles dans une étape
    @observable allStepsInserted: StepYes[] = []
    @observable idStepOr?: number = undefined
    @observable differe?: boolean = false
    @observable tabOrientation: boolean = false
    @observable reloadTab: boolean = false
    // observable
    @observable choose: boolean = false;
    @observable delegationTask: IDelegation = {
        idCircuit: undefined,
        idUser: undefined,
        dateBegin: undefined,
        dateEnd: undefined,
        originalRespOfTask: undefined,
        dateBeginToString: undefined,
        dateEndToString: undefined,
        dateResetUserOriginal: undefined,
        dateResetUserOriginalToString: undefined,
        validNow: false
    }
    @observable modeEditionModel: boolean = false;
    @observable delegation: IDelegationND = undefined;
    @observable allDeleg: IDelegationND[] = undefined;
    @observable modelDelegation: {model: IModelDelegation, all: IModelDelegation[]} = { model : undefined, all: []};
    @observable allTypeForm: ITypeForm[]=[];
}

const store = new Store();

export const getAllUsers = () => {
    axios.get(store.wsPath + "/1/users").then(response => {
        if (response.data.name != undefined && response.data.name == "SequelizeDatabaseError") {
            console.error("Error lors de la récupération des utilisateurs: " + response.data.original.code);
        }
        else {
            store.allUsers = response.data.sort(compareUser);
            const tempArray: { value: number, label: string, mail:string, isGroup ?: boolean }[] = [];
            response.data.forEach((suggestion) => {
                if (store.modeSharepoint == true && (suggestion.matricule.startsWith('AAD') || suggestion.matricule.startsWith('AD')) || suggestion.isGroup == true) { // a modifier et remplacer par un matricule groupe .
                    tempArray.push({
                        value: suggestion.id,
                        label: suggestion.lastname + " " + suggestion.firstname ,
                        mail : suggestion.email,
                        isGroup : suggestion.isGroup
                    })
                }
                else if (store.modeSharepoint == false && suggestion.matricule.startsWith('AAD') == false && suggestion.matricule.startsWith('AD') == false) {
                    tempArray.push({
                        value: suggestion.id,
                        label: suggestion.lastname + " " + suggestion.firstname ,
                        mail : suggestion.email,
                        isGroup : suggestion.isGroup
                    })
                }
            })
            store.suggestionsUser = tempArray.sort(compareUser);

            getAllRoles();
        }
    });
}
getAllUsers();

export const getAllRoles = ():Promise<any[]> => {
    return new Promise<any[]>((resolve,reject) => {
        axios.get(store.wsPath + `/1/roles/getAllRoles`).then(response => {
            store.allRoles = response.data;
            resolve(response.data);
        }).catch((error) => {
            console.log("Error 'getAllRoles' : ", error);
            reject();
        });
    });
}
getAllRoles();

if (store.modeSharepoint) {
    store.isNewDocument = false
    store.stateDocumentSelected = "En préparation"
}
export default store;